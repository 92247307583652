import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ISR_REVALIDATE } from 'utility/constant';

export type StripeValues = {
  num_slot: string;
  num_casino: string;
  num_promo: string;
  num_app: string;
  tot_gioco: string;
  avv_aperti: string;
  tot_live: string;
  tot_streaming: string;
};

export const externalApi = createApi({
  reducerPath: 'external api',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
  }),
  endpoints: (builder) => ({
    getHomepageStripeValues: builder.query<StripeValues, void>({
      query: () => `${process.env.NEXT_PUBLIC_HOMEPAGE_STRIPE_DATA_URL}`,
    }),
    getInstantPanel: builder.query<any, any>({
      query: (lng: string) => {
        return `${process.env.NEXT_PUBLIC_BASE_URL}/api/instant-panel?lng=${lng}`;
      },
      keepUnusedDataFor: ISR_REVALIDATE,
    }),
  }),
});

export const { useGetHomepageStripeValuesQuery, useLazyGetInstantPanelQuery } = externalApi;
