import { ApiPayload, ApiStatus } from 'features/api/thunkUtils';
import {
  DettaglioAvvenimentoSliceType,
  FEED_DETTAGLIO_AVVENIMENTO,
  dettaglioAvvenimentoDefault,
  feedDettaglioAvvenimentoActionType,
} from 'lib/ssr/sport/dettaglio-avvenimento/types';
import { Direttissima, DirettissimeResponsePayload } from 'lib/api/sport/direttissimeResponseApi';
import {
  EsitoMap,
  ScommessaMap,
  ScommessaResponse,
  ScommessaResponsePayload,
} from 'lib/api/sport/sportScommesseBySlugResponse';
import {
  FEED_INEVIDENZA,
  InEvidenzaSliceType,
  feedInEvidenzaActionType,
  inEvidenzaDefault,
} from 'lib/ssr/sport/in-evidenza/types';
import {
  FEED_MENUMANIFESTAZIONI,
  MenuManifestazioniSliceType,
  feedMenuManifestazioniActionType,
  menuManifestazioniDefault,
} from 'lib/ssr/sport/menu-manifestazioni/types';
import {
  FEED_TEMPLATE_AVVENIMENTO,
  TemplateAvvenimentoDto,
  feedTemplateAvvenimentoActionType,
} from 'lib/ssr/sport/templates/avvenimento/types';
import {
  FEED_TEMPLATE_MANIFESTAZIONE,
  TemplateManifestazioneDto,
  feedTemplateManifestazioneActionType,
} from 'lib/ssr/sport/templates/manifestazione/types';
import { FEED_TEMPLATE_TOPLIVE, feedTemplateTopLiveActionType } from 'lib/ssr/sport/templates/top-live/types';
import {
  FEED_TOPANTEPOST,
  TopAntePostSliceType,
  feedTopAntePostType,
  topAntePostDefault,
} from 'lib/ssr/sport/top-ante-post/types';
import {
  FEED_TOPGIOCATE,
  TopGiocateSliceType,
  feedTopGiocateActionType,
  topGiocateDefault,
} from 'lib/ssr/sport/top-giocate/types';
import {
  FEED_TOPMANIFESTAZIONI,
  TopManifestazioniSliceType,
  feedTopManifestazioniActionType,
  topManifestazioniDefault,
} from 'lib/ssr/sport/top-manifestazioni/types';
import { HYDRATE, hidrationActionType } from 'features/settings/type';
import { sequence as LiveFiltersKeys, LiveFiltersStatus, enFiltersKind } from 'features/live/liveFilters/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ProssimiEventiStateType,
  UpdateAllInfoAggPayload,
  ScoreBoardHasError,
  ScoreBoardProvider,
  SportFilters,
  UpdateCounterType,
  UpdateEventType,
} from './types';
import { SnaiRunnerResponsePayload, SnaiRunnerStateType } from 'lib/api/sport/snaiRunnerResponseApi';
import {
  SportsAddScommessaSignalREvent,
  SportsAvvenimentoEsposto,
  SportsCacheScommesseCoreDto,
  SportsCacheScommesseCoreWithTranslationsDto,
  SportsCacheTemplateAvvenimentoDto,
  SportsCacheTemplateDisciplinaLiveDto,
  SportsCacheTemplateDisciplinaLiveWithTranslationsDto,
  SportsCacheTemplateManifestazioneDto,
  SportsGruppoScommessa,
  SportsRemoveGruppoScommessaSignalREvent,
  SportsSignalRPacketElementDto,
  SportsUpdateAvvenimentoSignalREvent,
  SportsUpdateCountScommesseAttiveSignalREvent,
  SportsUpdateGruppoScommessaSignalREventWithTranslationsDto,
  SportsUpdateRisultatiniSignalREvent,
} from 'types/swagger';
import {
  applyAddAvvenimento,
  applyAvvenimento,
  applyInfoAggiuntiva,
  applyQuote,
  applyRemoveDataFromAvvenimento,
  applyRemoveGruppo,
  applyRisultatini,
  applyScommessa,
  applyScommesseAvvenimento,
  applyStatoScommessa,
  applyUpdateGruppo,
  decodePathname,
  parseScommessa,
} from './utils/utils';
import { feedLingUI, purgePageKeys } from 'hooks/useLingUI';
import { getProssimiEventi, getScommesse, getScommesseCustomPreMatch } from './actions';
import { hasValue, isMatch, isNotEmpty, isTruthy, purgeNulls } from 'utility/functions';
import { mockDettataglioAvvenimentoFromSlug, mockScommessaFromTemplate } from './mocked-data';

import type { ApiStatusType } from 'features/api/thunkUtils';
import { AppFragment } from 'enums/path-fragment';
import { SignalRMessage } from 'features/signalR/hubManager/types';
import {
  SportsAddInfoAggSignalREvent,
  SportsFavoritesMenuManifestazioneDto,
  SportsWidgetPiuGiocateType,
  SportsInfoAggSummaryDto,
} from 'types/swagger/sports';
import { UpdateProssimiEventiPayload } from 'lib/api/sport/prossimiEventiResponseApi';
import { appInsight, SeverityLevel } from 'components/appInsight';
import { getPathName } from 'features/location/utils';
import { navigate } from 'features/location/types';

export interface SportState {
  slug?: string | undefined;
  isInfoOpen: boolean;
  templateSlug?: string | undefined;
  isTopTemplate: boolean;
  selectedFilters: SportFilters;
  isNavMobileOpened: boolean;
  isStatsWidgetOpen: boolean;
  avvenimentoFilter: Array<string>;
  scoreboardHasError: ScoreBoardHasError;
  scommessaStatus?: ApiStatusType;
  selectedFiltersLive: LiveFiltersStatus;
  boxSearchTipiScommessa: {
    textSearched: string;
    indexFilter?: number;
    expandWindow: boolean;
    valueFilterSelected?:
      | {
          gruppoKey: string;
          value: string;
        }
      | undefined;
    isAccordionFilterClosed: boolean;
  };
  isVisualizzazioneEstesa: boolean;
  countersUpdateMessage: UpdateCounterType;
  scommessa?: ScommessaResponse;
  templateLive?: SportsCacheTemplateDisciplinaLiveDto;
  templateLiveStatus?: ApiStatusType;
  templateAvvenimento?: TemplateAvvenimentoDto;
  templateAvvenimentoStatus?: ApiStatusType;
  templateManifestazione?: TemplateManifestazioneDto;
  templateManifestazioneStatus?: ApiStatusType;
  templateDirettissime?: {
    direttissime: Direttissima[];
  } & SportsCacheScommesseCoreWithTranslationsDto;
  templateProssimiEventi?: ProssimiEventiStateType;
  templateSnaiRunner?: SnaiRunnerStateType;
  currentTime: Date | null;
  isBetGeniusLoaded: boolean;
  inEvidenza: InEvidenzaSliceType;
  topGiocate: TopGiocateSliceType;
  topAntePost: TopAntePostSliceType;
  topManifestazioni: TopManifestazioniSliceType;
  menuManifestazioni: MenuManifestazioniSliceType;
  dettaglioAvvenimento?: DettaglioAvvenimentoSliceType;
  selectedTabTopGiocate: string;
  selectedAllInfoAgg?: Record<string, SportsInfoAggSummaryDto>;
}

export const filtersDefault: SportFilters = {
  quota: [1, 100],
  orario: 'default',
  isResetted: false,
};

export const filtersLiveDefault: LiveFiltersStatus = {};

// Define the initial state using that type
export const initialState: SportState = {
  isInfoOpen: false,
  currentTime: null,
  isTopTemplate: false,
  selectedFilters: filtersDefault,
  isBetGeniusLoaded: false,
  isNavMobileOpened: false,
  isStatsWidgetOpen: false,
  avvenimentoFilter: [],
  scoreboardHasError: { hasError: false, provider: ScoreBoardProvider.Snai },
  selectedFiltersLive: filtersLiveDefault,
  countersUpdateMessage: {
    avvenimento: {
      counter: 0,
    },
    infoAgg: {
      counter: 0,
    },
    quote: {
      counter: 0,
    },
  },
  boxSearchTipiScommessa: {
    textSearched: '',
    expandWindow: false,
    isAccordionFilterClosed: false,
  },
  isVisualizzazioneEstesa: false,
  inEvidenza: inEvidenzaDefault,
  topGiocate: topGiocateDefault,
  topAntePost: topAntePostDefault,
  topManifestazioni: topManifestazioniDefault,
  menuManifestazioni: menuManifestazioniDefault,
  dettaglioAvvenimento: dettaglioAvvenimentoDefault,
  selectedTabTopGiocate: '1', // NOTE: DEFAULT TAB AS 1 DUE COMPONENT REQUIREMENTS
  selectedAllInfoAgg: {},
};

const _updateQuote = (st: SportState, data: EsitoMap) => {
  applyQuote(st?.scommessa, data);
  if (!!st.templateProssimiEventi) applyQuote(st?.templateProssimiEventi, data);
  if (!!st.templateSnaiRunner) applyQuote(st?.templateSnaiRunner, data);
  if (!!st.topAntePost) applyQuote(st.topAntePost.scommesse, data);
  if (!!st.inEvidenza) applyQuote(st.inEvidenza.scommesse, data);
  if (!!st.topGiocate) applyQuote(st.topGiocate.scommesse, data);
};

const _updateRisultatini = (st: SportState, data: SportsUpdateRisultatiniSignalREvent) => {
  applyRisultatini(st.scommessa, data);

  if (!!st.topAntePost) applyRisultatini(st.topAntePost.scommesse, data);
  if (!!st.inEvidenza) applyRisultatini(st.inEvidenza.scommesse, data);
  if (!!st.topGiocate) applyRisultatini(st.topGiocate.scommesse, data);
};

const _updateInfoAgg = (st: SportState, data: SportsAddInfoAggSignalREvent) => {
  applyInfoAggiuntiva(st.scommessa, data);
  if (!!st.templateProssimiEventi) applyInfoAggiuntiva(st.templateProssimiEventi, data);
  if (!!st.templateSnaiRunner) applyInfoAggiuntiva(st.templateSnaiRunner, data);
  if (!!st.topAntePost) applyInfoAggiuntiva(st.topAntePost.scommesse, data);
  if (!!st.inEvidenza) applyInfoAggiuntiva(st.inEvidenza.scommesse, data);
  if (!!st.topGiocate) applyInfoAggiuntiva(st.topGiocate.scommesse, data);
};

const _removeScommessa = (
  st: SportState,
  data: { scommesseToRemove: Array<string>; gruppoList: Array<SportsGruppoScommessa> }
) => {
  const { scommesseToRemove, gruppoList } = data ?? {};

  (scommesseToRemove ?? []).forEach((scommessaKey) => {
    delete st.scommessa?.scommessaMap[scommessaKey];
  });

  if (st.templateAvvenimento?.gruppoList) {
    st.templateAvvenimento.gruppoList = gruppoList;
  }
  if (st.inEvidenza) {
    st.inEvidenza.template = (st.inEvidenza.template ?? []).filter((x) => !scommesseToRemove.includes(x));
  }
};

const _updateStatoScommessa = (st: SportState, data: ScommessaMap) => {
  applyStatoScommessa(st.scommessa, data);
  if (!!st.templateProssimiEventi) applyStatoScommessa(st.templateProssimiEventi, data);
  if (!!st.topAntePost) applyStatoScommessa(st.topAntePost.scommesse, data);
  if (!!st.inEvidenza) applyStatoScommessa(st.inEvidenza.scommesse, data);
  if (!!st.topGiocate) applyStatoScommessa(st.topGiocate.scommesse, data);
};

const _updateAvvenimento = (st: SportState, data: SportsUpdateAvvenimentoSignalREvent) => {
  applyAvvenimento(st.scommessa, data);
  if (!!st.templateProssimiEventi) applyAvvenimento(st.templateProssimiEventi, data);
  if (!!st.templateSnaiRunner) applyAvvenimento(st.templateSnaiRunner, data);
  if (!!st.topAntePost) applyAvvenimento(st.topAntePost.scommesse, data);
  if (!!st.inEvidenza) applyAvvenimento(st.inEvidenza.scommesse, data);
  if (!!st.topGiocate) applyAvvenimento(st.topGiocate.scommesse, data);
};

const _updateScommesseAvvenimento = (
  st: SportState,
  data: { avvenimentoKey: string; allInfoAggAreActive: boolean }
) => {
  const { avvenimentoKey, allInfoAggAreActive } = data ?? {};
  applyScommesseAvvenimento(st.scommessa, avvenimentoKey, allInfoAggAreActive);

  if (!!st.templateProssimiEventi) {
    applyScommesseAvvenimento(st.templateProssimiEventi, avvenimentoKey, allInfoAggAreActive);
  }
  if (!!st.topAntePost) {
    applyScommesseAvvenimento(st.topAntePost.scommesse, avvenimentoKey, allInfoAggAreActive);
  }
  if (!!st.inEvidenza) {
    applyScommesseAvvenimento(st.inEvidenza.scommesse, avvenimentoKey, allInfoAggAreActive);
  }
  if (!!st.topGiocate) {
    applyScommesseAvvenimento(st.topGiocate.scommesse, avvenimentoKey, allInfoAggAreActive);
  }
};

const _removeAvvenimento = (st: SportState, data) => {
  const { avvenimentoKey, updateTemplate, updateProssimiEventi } = data ?? {};

  let removeAvvenimento = true;
  const indexToRemove = (st?.scommessa?.avvenimentoList ?? []).findIndex(
    (avv) => `${avv?.key}` === `${avvenimentoKey}`
  );
  if (st?.templateAvvenimento && indexToRemove > -1) {
    const templateSlug = Reflect.get(st?.templateAvvenimento ?? {}, 'slug');
    if (isNotEmpty(templateSlug)) {
      st.templateAvvenimento.gruppoList = [];
      removeAvvenimento = false;
    }
  }

  if (updateTemplate) {
    for (const key of ['templateLive', 'templateAvvenimento', 'templateManifestazione']) {
      const template = Reflect.get(st, key) ?? {};
      const slug = Reflect.get(template ?? {}, 'slug');
      if (isNotEmpty(slug)) {
        // console.log(`_removeAvvenimento(${slug}, ${key}, ${avvenimentoKey})`)
        st[key] = { ...updateTemplate, slug };
      }
    }
  }

  if (st.templateProssimiEventi?.tabList && updateProssimiEventi) {
    // console.log(`_removeAvvenimento.templateProssimiEventi`)
    st.templateProssimiEventi.tabList = [...updateProssimiEventi];
  }
  applyRemoveDataFromAvvenimento(st.scommessa, avvenimentoKey, removeAvvenimento);
  if (st.templateProssimiEventi) {
    applyRemoveDataFromAvvenimento(st.templateProssimiEventi, avvenimentoKey, removeAvvenimento);
  }
  if (st.topAntePost?.scommesse) {
    applyRemoveDataFromAvvenimento(st.topAntePost.scommesse, avvenimentoKey, removeAvvenimento);
  }
  if (st.inEvidenza?.scommesse) {
    applyRemoveDataFromAvvenimento(st.inEvidenza.scommesse, avvenimentoKey, removeAvvenimento);
  }
  if (st.topGiocate?.scommesse) {
    applyRemoveDataFromAvvenimento(st.topGiocate.scommesse, avvenimentoKey, removeAvvenimento);
  }
};

const _addAvvenimento = (
  st: SportState,
  data: {
    avvenimentoKey: string;
    avvenimento: SportsAvvenimentoEsposto;
    updateTemplate:
      | SportsCacheTemplateAvvenimentoDto
      | SportsCacheTemplateManifestazioneDto
      | SportsCacheTemplateDisciplinaLiveDto;
    updateProssimiEventi: UpdateProssimiEventiPayload;
  }
) => {
  const { avvenimentoKey, avvenimento, updateTemplate, updateProssimiEventi } = data ?? {};

  if (updateTemplate) {
    for (const key of ['templateLive', 'templateAvvenimento', 'templateManifestazione']) {
      const template = Reflect.get(st, key) ?? {};
      const slug = Reflect.get(template ?? {}, 'slug');
      if (`${avvenimento.slug}`.startsWith(`${slug}/`)) {
        // console.log(`_addAvvenimento(${slug}, ${key}, ${avvenimentoKey})`)
        st[key] = { ...updateTemplate, slug };
      }
    }
  }

  if (st.templateProssimiEventi?.tabList && updateProssimiEventi) {
    // console.log(`_addAvvenimento.templateProssimiEventi`)
    st.templateProssimiEventi.tabList = [...updateProssimiEventi];
  }

  // bug 2024-12-05 - aggiunto avvenimento della pagina precedente
  if (`${avvenimento.slug}`.startsWith(`${st.slug}/`)) {
    if (st.scommessa) {
      applyAddAvvenimento(st.scommessa, { [avvenimentoKey]: avvenimento });
    }
    if (st.templateProssimiEventi) {
      applyAddAvvenimento(st.templateProssimiEventi, { [avvenimentoKey]: avvenimento });
      // if (st.templateProssimiEventi.scommessaMap) {
      //   applyAddAvvenimento(st.templateProssimiEventi.scommessaMap, { [avvenimentoKey]: avvenimento });
      // }
    }
  }
};

const _addScommessa = (st: SportState, data: SportsAddScommessaSignalREvent) => {
  applyScommessa(st.scommessa!, data);
  if (st.templateProssimiEventi?.tabList) applyScommessa(st.templateProssimiEventi!, data);
};

const _updateGruppoScommessa = (st: SportState, data: SportsUpdateGruppoScommessaSignalREventWithTranslationsDto) => {
  const { gruppo } = data ?? {};

  for (const fieldName of ['templateAvvenimento', 'templateManifestazione']) {
    const templateSlug = Reflect.get(st[fieldName] ?? {}, 'slug');
    if (`${gruppo?.slug}`.startsWith(`${templateSlug}/`)) {
      applyUpdateGruppo(st[fieldName], gruppo);
    }
  }
  // st.inEvidenza does not contains gruppoList
};

const _removeGruppoScommessa = (st: SportState, data: SportsRemoveGruppoScommessaSignalREvent) => {
  const { key } = data ?? {};

  for (const fieldName of ['templateAvvenimento', 'templateManifestazione']) {
    const templateSlug = Reflect.get(st[fieldName] ?? {}, 'slug');
    if (isNotEmpty(templateSlug)) {
      applyRemoveGruppo(st[fieldName], key);
    }
  }
  // st.inEvidenza does not contains gruppoList
};

const _updateCountScommesseAttive = (st: SportState, data: SportsUpdateCountScommesseAttiveSignalREvent) => {
  const { avvKey, count } = data ?? {};
  st.scommessa?.avvenimentoList?.forEach((avvenimento) => {
    if (`${avvenimento?.key}` === `${avvKey}`) {
      avvenimento.numeroScommesse = count;
    }
  });
};

const reduceScommessa = (state: SportState, payload: ScommessaResponsePayload): void => {
  const { data, status } = payload ?? {};
  const nextSt = status ?? ApiStatus.idle;

  const scommessa = (state.scommessa || {}) as ScommessaResponse;

  const nextSlug = Reflect.get(data ?? {}, 'slug');
  const currentSlug = Reflect.get(state.scommessa ?? {}, 'slug');
  if (ApiStatus.loading == nextSt) {
    // loading set slug -> kind of "lock"
    Reflect.set(scommessa!, 'slug', nextSlug);
    state.scommessaStatus = nextSt;
    state.scommessa = scommessa;
    state.selectedAllInfoAgg = {};
    return;
  } else if (isNotEmpty(currentSlug) && nextSlug !== currentSlug) {
    // apply api callback result only when it is related to last loading slug
    return;
  }

  // ApiStatus.idle and ApiStatus.failed management differers just for data content
  // force avvenimentoList to be Array to simplify dettaglio avvenimento management
  state.scommessa = parseScommessa<ScommessaResponse>({ avvenimentoList: [], ...data });
  state.scommessaStatus = nextSt;
};

const reduceTemplateTopLive = (
  st: SportState,
  payload: ApiPayload<SportsCacheTemplateDisciplinaLiveWithTranslationsDto>,
  mockScommessa = false
) => {
  const { status, traduzioneMap, ...template } = payload ?? {};
  feedLingUI(traduzioneMap);

  const nestSt = status ?? ApiStatus.idle;
  const nextSlug = decodePathname(Reflect.get(template ?? {}, 'slug'));

  if (mockScommessa && !st.slug?.includes(nextSlug)) {
    if (st.scommessa && isNotEmpty(st.scommessa?.slug)) {
      Reflect.set(template, 'slug', st.scommessa.slug);
    }
    st.scommessa = mockScommessaFromTemplate(template);
  }

  switch (nestSt) {
    case ApiStatus.loading: {
      // delete st.scommessa;
      if (nextSlug !== st.templateSlug) {
        purgePageKeys();
        st.templateLive = template;
        st.templateLiveStatus = nestSt;
      }
      break;
    }
    case ApiStatus.idle: {
      st.templateLive = template;
      st.templateSlug = nextSlug;
      st.templateLiveStatus = nestSt;
      break;
    }
  }
};

const reduceTemplateAvvenimento = (
  st: SportState,
  payload: ApiPayload<TemplateAvvenimentoDto>,
  mockScommessa = false
) => {
  const { status, traduzioneMap, ...template } = (payload as any) ?? {};
  feedLingUI(traduzioneMap);

  const nestSt = status ?? ApiStatus.idle;
  const nextSlug = decodePathname(Reflect.get(template ?? {}, 'slug'));

  if (mockScommessa && !st.slug?.includes(nextSlug)) {
    if (st.scommessa && isNotEmpty(st.scommessa?.slug)) {
      Reflect.set(template, 'slug', st.scommessa.slug);
    }
    st.scommessa = mockScommessaFromTemplate(template);
  }

  switch (nestSt) {
    case ApiStatus.loading: {
      // delete st.scommessa;
      // delete st.templateManifestazione;
      if (nextSlug !== st.templateSlug) {
        purgePageKeys();
        st.templateAvvenimento = template;
        st.templateAvvenimentoStatus = nestSt;
      }
      break;
    }
    case ApiStatus.idle: {
      st.templateSlug = nextSlug;
      st.templateAvvenimento = template;
      st.templateAvvenimentoStatus = nestSt;
      break;
    }
  }
};

const reduceTemplateManifestazione = (
  st: SportState,
  payload: ApiPayload<TemplateManifestazioneDto>,
  mockScommessa = false
) => {
  const { status, traduzioneMap, ...template } = (payload as any) ?? {};

  feedLingUI(traduzioneMap);

  const nestSt = status ?? ApiStatus.idle;
  const nextSlug = decodePathname(Reflect.get(template ?? {}, 'slug'));

  if (mockScommessa && !st.slug?.includes(nextSlug)) {
    if (st.scommessa && isNotEmpty(st.scommessa?.slug)) {
      Reflect.set(template, 'slug', st.scommessa.slug);
    }
    st.scommessa = mockScommessaFromTemplate(template);
  }

  switch (nestSt) {
    case ApiStatus.loading: {
      // delete st.scommessa;
      // delete st.templateAvvenimento;
      if (nextSlug !== st.templateSlug) {
        purgePageKeys();
        st.templateManifestazione = template;
        st.templateManifestazioneStatus = nestSt;
      }
      break;
    }
    case ApiStatus.idle: {
      st.templateSlug = nextSlug;
      st.templateManifestazione = template;
      st.templateManifestazioneStatus = nestSt;
      break;
    }
  }
};

const reduceDettaglioAvvenimento = (st: SportState, payload: DettaglioAvvenimentoSliceType): void => {
  let { slug, live, isLoading, firstCompetitor, secondCompetitor, ...oth } = payload ?? {};

  const fallback = mockDettataglioAvvenimentoFromSlug(slug) ?? {};

  if (isNotEmpty(slug) && purgeNulls(oth) === undefined) {
    st.dettaglioAvvenimento = { ...fallback, slug } as DettaglioAvvenimentoSliceType;
    return;
  }

  if (!live && !!fallback.live) {
    live = fallback.live;
  }

  if (!firstCompetitor && !!fallback.firstCompetitor) {
    firstCompetitor = fallback.firstCompetitor;
  }

  if (!secondCompetitor && !!fallback.secondCompetitor) {
    secondCompetitor = fallback.secondCompetitor;
  }

  st.dettaglioAvvenimento = { ...oth, slug, live, isLoading, firstCompetitor, secondCompetitor };
};

type ReducerSportInfoAggSelectionState = {
  avvenimentiKey: Array<string>;
  scommesse: ScommessaResponse | SportsCacheScommesseCoreDto;
};
type ReducerSportInfoAggSelectionPayload = {
  infoTipoScommessaKey: string;
  selectedInfoAggHex: string;
};
const reduceInfoAggSelection = (
  { avvenimentiKey, scommesse }: ReducerSportInfoAggSelectionState,
  { infoTipoScommessaKey, selectedInfoAggHex }: ReducerSportInfoAggSelectionPayload
): void => {
  const infoAggIndex = scommesse.infoAggIndex;

  avvenimentiKey?.forEach((key) => {
    const infoAggKey = `${key}-${infoTipoScommessaKey}`;
    const element = infoAggIndex?.[infoAggKey];
    if (element) {
      const selectedInfoAggKey = `${infoAggKey}-${selectedInfoAggHex}`;
      if (element.nextInfoAgg?.includes(selectedInfoAggKey)) {
        element.defaultInfoAgg = selectedInfoAggKey;
      }
    }
  });
};

export const sportSlice = createSlice({
  name: 'sport',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    initScommessaResponse: (state, action: PayloadAction<ScommessaResponsePayload>) => {
      reduceScommessa(state, action.payload);
    },
    templateManifestazioneLoadingUpdated: (state) => {
      state.templateManifestazioneStatus = ApiStatus.loading;
    },
    initTemplateDirettissime(state, { payload }: PayloadAction<DirettissimeResponsePayload>) {
      const { data } = payload;
      if (data) {
        state.templateDirettissime = data;
      }
    },
    initTemplateSnaiRunner(state, { payload }: PayloadAction<SnaiRunnerResponsePayload>) {
      const { data } = payload;
      if (data) {
        state.templateSnaiRunner = data;
      }
    },
    sportMessage: (state, { payload }: PayloadAction<Array<SportsSignalRPacketElementDto>>) => {
      payload.forEach(({ message, event }) => {
        const { traduzioneMap, ...data } = event ?? {};

        if (!!traduzioneMap) {
          feedLingUI(traduzioneMap);
        }

        switch (message) {
          case SignalRMessage.AddInfoAgg:
          case SignalRMessage.UpdateInfoAgg: {
            _updateInfoAgg(state, data);
            break;
          }
          // case SignalRMessage.UpdateStatoInfoAgg: { _updateStatoInfoAgg(state, data); break; }
          case SignalRMessage.UpdateQuote: {
            _updateQuote(state, data);
            break;
          }
          case SignalRMessage.UpdateRisultatini: {
            _updateRisultatini(state, data);
            break;
          }
          case SignalRMessage.RemoveScommessa: {
            _removeScommessa(state, data);
            break;
          }
          case SignalRMessage.UpdateStatoScommessa: {
            _updateStatoScommessa(state, data);
            break;
          }
          case SignalRMessage.UpdateAvvenimento: {
            _updateAvvenimento(state, data);
            break;
          }
          case SignalRMessage.UpdateScommesseAvvenimento: {
            _updateScommesseAvvenimento(state, data);
            break;
          }
          case SignalRMessage.RemoveAvvenimento: {
            _removeAvvenimento(state, data);
            break;
          }
          case SignalRMessage.AddAvvenimento: {
            _addAvvenimento(state, data);
            break;
          }
          case SignalRMessage.AddScommessa: {
            _addScommessa(state, data);
            break;
          }
          case SignalRMessage.UpdateGruppoScommessa: {
            _updateGruppoScommessa(state, data);
            break;
          }
          case SignalRMessage.RemoveGruppoScommessa: {
            _removeGruppoScommessa(state, data);
            break;
          }
          case SignalRMessage.UpdateCountScommesseAttive: {
            _updateCountScommesseAttive(state, data);
            break;
          }
          default: {
            const msg = `[sportSlice] - loadMessage: ${message} does not match any action`;
            appInsight?.trackTrace({ message: msg, severityLevel: SeverityLevel.Information });
            break;
          }
        }
      });
    },
    removeAvvenimentoContentTabList: (state, { payload }: PayloadAction<string>) => {
      if (!state.templateProssimiEventi) return;
      state.templateProssimiEventi.tabList.forEach((tab) => {
        tab?.contentTabList?.forEach((contentTab) => {
          const index = contentTab.avvenimentoList?.findIndex((avv) => `${avv?.key}` === `${payload}`) ?? -1;
          if (index > -1) {
            contentTab.avvenimentoList?.splice(index, 1);
          }
        });
        tab.contentTabList = tab.contentTabList?.filter((contentTab) => (contentTab.avvenimentoList ?? []).length > 0);
      });
      state.templateProssimiEventi.tabList = state.templateProssimiEventi?.tabList.filter(
        (tab) => (tab.contentTabList ?? []).length > 0
      );
    },
    setSlug: (state, action: PayloadAction<string>) => {
      state.slug = decodePathname(action.payload);
    },
    toggleNavMobile: (state, action: PayloadAction<boolean>) => {
      state.isNavMobileOpened = action.payload;
    },
    quotaSelected: (state, action: PayloadAction<number[]>) => {
      state.selectedFilters.quota = action.payload;
    },
    orarioSelected: (state, action: PayloadAction<string>) => {
      state.selectedFilters.orario = action.payload;
    },
    setIsResettedFilter: (state, action: PayloadAction<boolean>) => {
      state.selectedFilters.isResetted = action.payload;
    },
    resetMenuManifestazioniFilters: (state) => {
      state.selectedFilters = filtersDefault;
      state.selectedFiltersLive = filtersLiveDefault;
    },
    setFilterAvvenimento: (state, action: PayloadAction<string>) => {
      if (!state.avvenimentoFilter.includes(action.payload)) {
        state.avvenimentoFilter.push(action.payload);
      }
    },
    removeFilterAvvenimento: (state, action: PayloadAction<string>) => {
      state.avvenimentoFilter = state.avvenimentoFilter.filter((item) => item !== action.payload);
    },
    resetFilterAvvenimento: (state) => {
      state.avvenimentoFilter = [];
    },
    setTextSearchedTipiScommessa: (state, action: PayloadAction<string>) => {
      state.boxSearchTipiScommessa.textSearched = action.payload;
    },
    setIndexFilterTipiScommessa: (state, action: PayloadAction<number | undefined>) => {
      state.boxSearchTipiScommessa.indexFilter = action.payload;
    },
    setExpandWindowTipiScommessa: (state, action: PayloadAction<boolean>) => {
      state.boxSearchTipiScommessa.expandWindow = action.payload;
    },
    setValueFilterSelectedTipiScommessa: (state, action: PayloadAction<{ gruppoKey: string; value: string }>) => {
      state.boxSearchTipiScommessa.valueFilterSelected = action.payload;
    },
    resetValueFilterSelectedTipiScommessa: (state, action: PayloadAction<undefined>) => {
      state.boxSearchTipiScommessa.valueFilterSelected = action.payload;
    },
    setAccordionFilter: (state, action: PayloadAction<boolean>) => {
      state.boxSearchTipiScommessa.isAccordionFilterClosed = action.payload;
    },
    setIsInfoOpen: (state, action: PayloadAction<boolean>) => {
      state.isInfoOpen = action.payload;
    },
    setScoreboardHasError: (state, { payload: { hasError, provider } }: PayloadAction<ScoreBoardHasError>) => {
      state.scoreboardHasError.hasError = hasError;
      if (provider) state.scoreboardHasError.provider = provider;
    },
    increaseCounterMessage: (state, { payload }: PayloadAction<{ type: UpdateEventType }>) => {
      state.countersUpdateMessage[payload.type].counter++;
    },
    resetCounterMessage: (state) => {
      Object.keys(state.countersUpdateMessage).forEach((key) => (state.countersUpdateMessage[key].counter = 0));
    },
    setLiveFilters: (state, { payload }: PayloadAction<Record<string, any>>) => {
      for (const k of LiveFiltersKeys) {
        if (!hasValue(payload[k])) {
          continue;
        }
        const list = state.selectedFiltersLive[k] ?? [];
        const idx = isMatch(enFiltersKind.Minutaggio, `^${k}$`)
          ? list.findIndex((x) => x.from === payload[k].from)
          : list.indexOf(payload[k]);
        if (idx < 0) {
          list.push(payload[k]);
        } else {
          list.splice(idx, 1);
        }
        if (list.length > 0) {
          state.selectedFiltersLive[k] = list.sort();
        } else {
          delete state.selectedFiltersLive[k];
        }
      }
    },
    resetLiveFilters: (state) => {
      state.selectedFiltersLive = filtersLiveDefault;
    },
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload;
    },
    setIsBetGeniusLoaded: (state, { payload }: PayloadAction<boolean>) => {
      state.isBetGeniusLoaded = payload;
    },
    setIsLoadingMostPlayed: (state, { payload }: PayloadAction<boolean>) => {
      state.topGiocate.isLoading = payload;
    },
    setSelectedTopGiocateTab: (state, { payload }: PayloadAction<string>) => {
      state.selectedAllInfoAgg = {};
      state.selectedTabTopGiocate = payload;
    },
    setSelectedAllInfoAgg: (state, { payload }: PayloadAction<Record<string, SportsInfoAggSummaryDto>>) => {
      state.selectedAllInfoAgg = {
        ...state.selectedAllInfoAgg,
        ...payload,
      };
    },
    setIsMarcatore: (state, { payload }: PayloadAction<boolean>) => {
      if (state.scommessa) {
        state.scommessa.isMarcatore = payload;
      }
    },
    updateAllInfoAggTopGiocate: (state, { payload }: PayloadAction<UpdateAllInfoAggPayload>) => {
      if (state.topGiocate) {
        const { hexValue } = state.selectedAllInfoAgg?.[payload.infoTipoScommessaKey] ?? {};
        const { infoTipoScommessaKey } = payload;
        const { template, scommesse } = state.topGiocate;
        const selectedTab = +state.selectedTabTopGiocate - 1;

        const avvenimentiKey =
          template?.[selectedTab].contentTabList?.flatMap(({ avvenimentoList }) =>
            (avvenimentoList ?? []).map(({ key }) => key ?? '')
          ) ?? [];

        reduceInfoAggSelection(
          { avvenimentiKey, scommesse: scommesse ?? {} },
          { infoTipoScommessaKey, selectedInfoAggHex: hexValue! }
        );
      }
    },
    updateAllInfoAggManifestazioni: (state, { payload }: PayloadAction<UpdateAllInfoAggPayload>) => {
      if (state.scommessa) {
        const { hexValue } = state.selectedAllInfoAgg?.[payload.infoTipoScommessaKey] ?? {};
        const avvenimentiKey = state.scommessa.avvenimentoList.map(({ key }) => key ?? '');
        reduceInfoAggSelection(
          { avvenimentiKey, scommesse: state.scommessa },
          { infoTipoScommessaKey: payload.infoTipoScommessaKey, selectedInfoAggHex: hexValue! }
        );
      }
    },
    updatePreferitiManifestazione: (state, { payload }: PayloadAction<{ method: 'add' | 'delete'; id: string }>) => {
      const { method, id } = payload;
      const preferiti = state.menuManifestazioni.preferiti;
      if (method === 'add') {
        const manifestazione = state.menuManifestazioni.gruppi
          ?.flatMap((gruppo) => gruppo.manifestazioni || [])
          .find((manifestazione) => manifestazione.id.toString() === id);
        if (manifestazione) {
          const { id, slug, descrizione, descrizioneTrKey } = manifestazione;
          const preferito = {
            id: id.toString(),
            slug,
            descrizione,
            descrizioneTrKey,
          } as Partial<SportsFavoritesMenuManifestazioneDto>;
          preferiti?.push(preferito as SportsFavoritesMenuManifestazioneDto);
        }
      } else {
        state.menuManifestazioni.preferiti = preferiti?.filter((preferito) => preferito.id !== id);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action: hidrationActionType) => {
        // removed // const templateDisciplina = Reflect.get(action.payload ?? {}, 'templateDisciplina')

        const templateTopLive = Reflect.get(action.payload ?? {}, 'templateTopLive');
        reduceTemplateTopLive(state, { ...templateTopLive, status: ApiStatus.idle }, !!templateTopLive);

        const templateAvvenimento = Reflect.get(action.payload ?? {}, 'templateAvvenimento');
        reduceTemplateAvvenimento(state, { ...templateAvvenimento, status: ApiStatus.idle }, !!templateAvvenimento);

        const templateManifestazione = Reflect.get(action.payload ?? {}, 'templateManifestazione');
        reduceTemplateManifestazione(
          state,
          { ...templateManifestazione, status: ApiStatus.idle },
          !!templateManifestazione
        );

        const dettaglioAvvenimento = Reflect.get(action.payload ?? {}, 'dettaglioAvvenimento');
        reduceDettaglioAvvenimento(state, { ...dettaglioAvvenimento, isLoading: false });

        const inEvidenza = Reflect.get(action.payload ?? {}, 'inEvidenza');
        if (hasValue(inEvidenza)) {
          // state.inEvidenza = { ...inEvidenza, isLoading: true};
          const { scommesse, ...oth } = inEvidenza;
          state.inEvidenza = {
            ...oth,
            isLoading: false,
            scommesse: parseScommessa<SportsCacheScommesseCoreDto>(scommesse),
          };
        } else {
          state.inEvidenza = inEvidenzaDefault;
        }

        const topGiocate = Reflect.get(action.payload ?? {}, 'topGiocate');
        if (hasValue(topGiocate)) {
          // state.topGiocate = { ...topGiocate, isLoading: true };
          const { scommesse, ...oth } = topGiocate;
          state.topGiocate = {
            ...oth,
            isLoading: false,
            scommesse: parseScommessa<SportsCacheScommesseCoreDto>(scommesse),
          };
        } else {
          state.topGiocate = topGiocateDefault;
        }

        const topAntePost = Reflect.get(action.payload ?? {}, 'topAntePost');
        if (hasValue(topAntePost)) {
          // state.topAntePost = { ...topAntePost, isLoading: true };
          const { scommesse, ...oth } = topAntePost;
          state.topAntePost = {
            ...oth,
            isLoading: false,
            scommesse: parseScommessa<SportsCacheScommesseCoreDto>(scommesse),
          };
        } else {
          state.topAntePost = topAntePostDefault;
        }

        const topManifestazioni = Reflect.get(action.payload ?? {}, 'topManifestazioni');
        if (hasValue(topManifestazioni)) {
          state.topManifestazioni = { ...topManifestazioni, isLoading: false };
        } else {
          state.topManifestazioni = topManifestazioniDefault;
        }

        const menuManifestazioni = Reflect.get(action.payload ?? {}, 'menuManifestazioni');
        if (hasValue(menuManifestazioni)) {
          state.menuManifestazioni = { ...menuManifestazioni, isLoading: false };
        } else {
          state.menuManifestazioni = menuManifestazioniDefault;
        }
      })
      .addCase(FEED_TEMPLATE_TOPLIVE, (state, action: feedTemplateTopLiveActionType) => {
        reduceTemplateTopLive(state, { ...action.payload, status: ApiStatus.idle }, false);
      })
      .addCase(FEED_TEMPLATE_AVVENIMENTO, (state, action: feedTemplateAvvenimentoActionType) => {
        reduceTemplateAvvenimento(state, { ...action.payload, status: ApiStatus.idle }, false);
      })
      .addCase(FEED_TEMPLATE_MANIFESTAZIONE, (state, action: feedTemplateManifestazioneActionType) => {
        reduceTemplateManifestazione(state, { ...action.payload, status: ApiStatus.idle }, false);
      })
      .addCase(FEED_DETTAGLIO_AVVENIMENTO, (state, action: feedDettaglioAvvenimentoActionType) => {
        reduceDettaglioAvvenimento(state, { ...action.payload, isLoading: false });
      })
      .addCase(FEED_INEVIDENZA, (state, action: feedInEvidenzaActionType) => {
        // state.inEvidenza = { ...action.payload }; delete state.inEvidenza.isLoading;
        const { scommesse, ...oth } = action.payload;
        state.inEvidenza = {
          ...oth,
          scommesse: parseScommessa<SportsCacheScommesseCoreDto>(scommesse),
        };
      })
      .addCase(FEED_TOPGIOCATE, (state, action: feedTopGiocateActionType) => {
        // state.topGiocate = { ...action.payload }; delete state.topGiocate.isLoading;
        // RESET FIRST SELECTED TAB
        state.selectedTabTopGiocate = '1';
        const { scommesse, ...oth } = action.payload;
        state.topGiocate = {
          ...oth,
          scommesse: parseScommessa<SportsCacheScommesseCoreDto>(scommesse),
        };
      })
      .addCase(FEED_TOPANTEPOST, (state, action: feedTopAntePostType) => {
        // state.topAntePost = { ...action.payload }; delete state.topAntePost.isLoading;
        const { scommesse, ...oth } = action.payload;
        state.topAntePost = {
          ...oth,
          scommesse: parseScommessa<SportsCacheScommesseCoreDto>(scommesse),
        };
      })
      .addCase(FEED_TOPMANIFESTAZIONI, (state, action: feedTopManifestazioniActionType) => {
        state.topManifestazioni = { ...action.payload };
        delete state.topManifestazioni.isLoading;
      })
      .addCase(FEED_MENUMANIFESTAZIONI, (state, action: feedMenuManifestazioniActionType) => {
        const { traduzioneMap } = action.payload;
        feedLingUI(traduzioneMap);
        state.menuManifestazioni = { ...action.payload };
        delete state.menuManifestazioni.isLoading;
      })
      .addCase(navigate.pending, (state, action) => {
        const nextPath = `${action?.meta?.arg}/`.toLowerCase();
        const srcPath = `${getPathName()}/`.toLowerCase();

        if (decodeURI(nextPath) === decodeURI(srcPath)) {
          return;
        }

        for (const key of ['templateLive', 'templateAvvenimento', 'templateManifestazione']) {
          state[key] = state[key] ?? {};
          state[key].isLoading = true;
        }

        delete state.scommessaStatus;

        const [nextRoot, nextDisciplina] = nextPath
          .split('/')
          .filter((x) => !!x)
          .splice(0, 2);

        const [prevRoot, prevDisciplina] = srcPath
          .split('/')
          .filter((x) => !!x)
          .splice(0, 2);

        const isRootChanging = nextRoot !== prevRoot;
        const isDisciplinaChanging = prevDisciplina !== nextDisciplina;
        const isFromDisciplinaToRoot = isTruthy(prevDisciplina?.length) && !isTruthy(nextDisciplina?.length);
        if (isRootChanging || isDisciplinaChanging || isFromDisciplinaToRoot) {
          state.selectedFilters = filtersDefault;
          state.selectedFiltersLive = filtersLiveDefault;
        }

        // setting isLoading attribute to entities managed via SSR
        state.inEvidenza = state.inEvidenza ?? inEvidenzaDefault;
        state.inEvidenza.isLoading = true;

        state.topGiocate = state.topGiocate ?? topGiocateDefault;
        state.topGiocate.isLoading = true;

        state.topAntePost = state.topAntePost ?? topAntePostDefault;
        state.topAntePost.isLoading = true;

        state.topManifestazioni = state.topManifestazioni ?? topManifestazioniDefault;
        state.topManifestazioni.isLoading = true;

        state.menuManifestazioni = state.menuManifestazioni ?? menuManifestazioniDefault;
        state.menuManifestazioni.isLoading = true;

        state.dettaglioAvvenimento = state.dettaglioAvvenimento ?? dettaglioAvvenimentoDefault;
        state.dettaglioAvvenimento.isLoading = true;

        state.selectedTabTopGiocate = '1';
        // USED FOR RESET TEMPLATE AVVENIMENTO
        delete state.templateAvvenimento;
        state.selectedAllInfoAgg = {};
      })
      .addCase(navigate.fulfilled, (state, action) => {
        const nextSlug = decodePathname(action.payload);
        const isRelatedPage = isMatch(nextSlug, `^(\/?)(${AppFragment.Sport}|${AppFragment.Live})(\/|$)`);

        if (isRelatedPage) {
          state.slug = nextSlug;
        } else {
          state = { ...initialState, slug: nextSlug };
        }
      })
      .addCase(getProssimiEventi.rejected, (state, { meta }) => {
        if (meta.arg.slug === state.templateProssimiEventi?.slug) {
          state.templateProssimiEventi = {
            slug: meta.arg.slug,
            status: ApiStatus.failed,
          } as unknown as ProssimiEventiStateType;
        }
      })
      .addCase(getProssimiEventi.pending, (state, { meta }: any) => {
        const { slug, ts } = meta.arg ?? {};
        state.templateProssimiEventi = state.templateProssimiEventi ?? ({} as ProssimiEventiStateType);
        const currentSlug = Reflect.get(state.templateProssimiEventi, 'slug');
        const currentStatus = Reflect.get(state.templateProssimiEventi, 'status');

        if (currentSlug !== slug || currentStatus === ApiStatus.failed) {
          Reflect.set(state.templateProssimiEventi, 'ts', ts);
          Reflect.set(state.templateProssimiEventi, 'slug', slug);
          Reflect.set(state.templateProssimiEventi, 'status', ApiStatus.loading);
        }
      })
      .addCase(getProssimiEventi.fulfilled, (state, { meta, payload }) => {
        const { slug, ts } = meta.arg ?? {};
        const currentTs = Reflect.get(state.templateProssimiEventi ?? {}, 'ts');
        const currentSlug = Reflect.get(state.templateProssimiEventi ?? {}, 'slug');
        if (slug === currentSlug && ts === currentTs) {
          if (purgeNulls(payload)) {
            state.templateProssimiEventi = parseScommessa<ProssimiEventiStateType>({
              ...payload,
              slug,
              status: ApiStatus.idle,
            });
          } else {
            state.templateProssimiEventi = {
              slug: meta.arg.slug,
              status: ApiStatus.idle,
            } as unknown as ProssimiEventiStateType;
          }
        }
      })
      .addCase(getScommesseCustomPreMatch.pending, (state, { meta }: any) => {
        reduceScommessa(state, { data: { slug: meta?.arg } as any, status: ApiStatus.loading });
        if (state.scommessa) {
          state.scommessa.slug = meta?.arg;
        }
      })
      .addCase(getScommesseCustomPreMatch.rejected, (state, { meta }: any) => {
        reduceScommessa(state, { data: { slug: meta?.arg } as any, status: ApiStatus.failed });
      })
      .addCase(getScommesseCustomPreMatch.fulfilled, (state, { payload }) => {
        reduceScommessa(state, { data: payload, status: ApiStatus.idle });
      })
      .addCase(getScommesse.fulfilled, (state, { meta }: any) => {
        const { isMarcatore } = meta.arg ?? {};
        if (state.scommessa?.isMarcatore !== isMarcatore) {
          state.scommessa!.isMarcatore = isMarcatore;
        }
      });
  },
});

export const {
  setSlug,
  sportMessage,
  setIsInfoOpen,
  quotaSelected,
  setIsMarcatore,
  orarioSelected,
  setLiveFilters,
  setCurrentTime,
  toggleNavMobile,
  resetLiveFilters,
  setAccordionFilter,
  setIsResettedFilter,
  resetCounterMessage,
  setIsBetGeniusLoaded,
  setFilterAvvenimento,
  setSelectedAllInfoAgg,
  initScommessaResponse,
  setScoreboardHasError,
  setIsLoadingMostPlayed,
  increaseCounterMessage,
  initTemplateSnaiRunner,
  resetFilterAvvenimento,
  removeFilterAvvenimento,
  initTemplateDirettissime,
  setSelectedTopGiocateTab,
  updateAllInfoAggTopGiocate,
  setIndexFilterTipiScommessa,
  setTextSearchedTipiScommessa,
  setExpandWindowTipiScommessa,
  updatePreferitiManifestazione,
  resetMenuManifestazioniFilters,
  updateAllInfoAggManifestazioni,
  removeAvvenimentoContentTabList,
  setValueFilterSelectedTipiScommessa,
  templateManifestazioneLoadingUpdated,
  resetValueFilterSelectedTipiScommessa,
} = sportSlice.actions;

export default sportSlice.reducer;
