import { Esito, EsitoMap, InfoTipoScommessa, ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';
import {
  InfoAggiuntivaBilanciataType,
  ProssimiEventiStateType,
  ScoreBoardProvider,
  TipoScommessaDescrizioniType,
} from './types';
import {
  SportsBetRadarGiocatoreDto,
  SportsCacheTemplateAvvenimentoDto,
  SportsCompetitor,
  SportsInfoAggData,
  SportsInfoAggIndex,
  SportsInfoAggMeta,
  SportsInfoEsito,
  SportsInfoTipoScommessa,
  SportsLiveSection,
  SportsPiuGiocateTabDto,
  SportsWidgetPiuGiocateEnum,
} from 'types/swagger';
import { deepEqual, shallowEqual } from 'fast-equals';
import { dynamicDataLoader, hasValue, isMatch, isNotEmpty, isTruthy, purgeNulls } from 'utility/functions';
import { filtersDefault, filtersLiveDefault, initialState } from './sportSlice';

import { ApiStatus } from 'features/api/thunkUtils';
import { AppFragment } from 'enums/path-fragment';
import { KeyManagerSport } from './utils/keyManager';
import { RootState } from 'lib/centralStore';
import { appInsight } from 'components/appInsight/AppInsight';
import { createSelector } from '@reduxjs/toolkit';
import { sortEsiti } from './utils/utils';

export type ScommessaResponseSelectorType = (_state: RootState) => ScommessaResponse | undefined;
export type TabListResponseSelectorType = (_state: RootState) => ProssimiEventiStateType | undefined;

const _self = ({ sport }: RootState) => {
  return sport ?? initialState;
};

const _selfMarcatori = (state: RootState) => state.marcatori;
export const selectSelectedAvvenimento = createSelector([_selfMarcatori], (state) => state.avvenimentoSelected);

export const selectIsInfoOpen = createSelector([_self], ({ isInfoOpen }) => isTruthy(isInfoOpen));
export const selectIsNavMobileOpened = createSelector([_self], ({ isNavMobileOpened }) => isTruthy(isNavMobileOpened));

export const selectSelectedFilter = createSelector([_self], (state) => state.selectedFilters);
export const selectSelectedFilterLive = createSelector([_self], (state) => state.selectedFiltersLive);

export const _selectTopGiocate = createSelector([_self], (state) => state.topGiocate);

export const selectTopGiocateTabs = createSelector(
  [_selectTopGiocate],
  (topGiocate) => {
    const list: Array<SportsWidgetPiuGiocateEnum> = [
      { key: 'prematch', value: SportsWidgetPiuGiocateEnum.Prematch },
      { key: 'prossimiEventi', value: SportsWidgetPiuGiocateEnum.ProssimiEventi },
      { key: 'live', value: SportsWidgetPiuGiocateEnum.Live },
    ]
      .filter((x) => isTruthy(topGiocate[x.key]))
      .map((x) => x.value);

    const selected = Reflect.get(topGiocate, 'type') as SportsWidgetPiuGiocateEnum;

    if (!list.includes(selected)) {
      list.push(selected);
    }

    return {
      list,
      selected,
    };
  },
  {
    memoizeOptions: { resultEqualityCheck: shallowEqual },
  }
);

export const mostPlayedTabs = createSelector([_self], (state) => {
  return {
    prematch: state.topGiocate.prematch,
    prossimiEventi: state.topGiocate.prossimiEventi,
    live: state.topGiocate.live,
  };
});

export const selectSelectedFilterLiveDetails = createSelector(
  [(st, _key) => selectSelectedFilterLive(st), (_st, key) => key],
  (filters, key) => Reflect.get(filters ?? {}, key)
);

export const selectAvvenimentoFilter = createSelector([_self], ({ avvenimentoFilter }) => avvenimentoFilter);
export const selectSelectedTopGiocateTab = createSelector(
  [_self],
  ({ selectedTabTopGiocate }) => selectedTabTopGiocate
);

export const selectInfoTemplateLive = createSelector([_self], (state) => {
  const { templateLive, templateLiveStatus, scommessaStatus } = state ?? {};
  return { templateLive, templateLiveStatus, scommessaStatus };
});

export const selectGruppoList = createSelector([_self], (state) => {
  const gruppoList = state.templateAvvenimento?.gruppoList ?? [];
  return [...gruppoList].sort((a, b) => a.priorita - b.priorita);
});

export const selectSlug = createSelector([_self], (state) => state.slug);
export const selectSelectedAllInfoAgg = createSelector([_self], (state) => state.selectedAllInfoAgg);

export const selectSelectedAllInfoAggByInfoTipoScommessaKey = createSelector(
  [selectSelectedAllInfoAgg, (_state, key) => key],
  (infoAggDict, key: string) => infoAggDict?.[key]
);

export type AvvenimentoHeaderInfoType = {
  isLive: boolean;
  providerId?: ScoreBoardProvider;
  hasScoreBoard: boolean;
  avvenimentoKey?: string;
  providerVersion?: number;
} & Pick<SportsLiveSection, 'coodsId' | 'idDisciplina' | 'betRadarMatchId' | 'betGeniusMatchId'> &
  Pick<SportsCacheTemplateAvvenimentoDto, 'idProgramma' | 'idAvvenimento' | 'idDisciplina' | 'idManifestazione'>;

export const selectAvvenimentoHeaderInfo = createSelector(
  [_self],
  ({ templateAvvenimento }): AvvenimentoHeaderInfoType => {
    const { key, live, idProgramma, idAvvenimento, idDisciplina, idManifestazione, externalProvider } =
      (templateAvvenimento ?? {}) as SportsCacheTemplateAvvenimentoDto;

    const result = {
      idProgramma,
      idDisciplina,
      idAvvenimento,
      avvenimentoKey: key,
      idManifestazione,
    } as AvvenimentoHeaderInfoType;

    if (live) {
      Reflect.set(result, 'coodsId', live.coodsId);
      Reflect.set(result, 'providerId', live.scoreBoardProvider);
      Reflect.set(result, 'idDisciplina', live.idDisciplina);
      Reflect.set(result, 'providerVersion', live.scoreBoardProviderVersion);
      Reflect.set(result, 'betRadarMatchId', live.betRadarMatchId);
      Reflect.set(result, 'betGeniusMatchId', live.betGeniusMatchId);
    } else if (hasValue(externalProvider)) {
      Reflect.set(result, 'providerId', Number(`${externalProvider}`));
    }

    result.isLive = !!live;
    result.hasScoreBoard = [result.coodsId, result.providerId, result.betRadarMatchId, result.betGeniusMatchId].some(
      (element) => {
        const value = Number(element ?? 0);
        return value !== 0;
      }
    );

    // console.log(`avvenimento-header-info: ${JSON.stringify(result)}`);

    return result;
  }
);

export const selectMenuManifestazioni = createSelector([_self], ({ menuManifestazioni }) => menuManifestazioni);

export const selectPreferitiManifestazione = createSelector([selectMenuManifestazioni], ({ preferiti }) => preferiti);
// export const selectIsMenuManifestazioniLoading = createSelector([selectMenuManifestazioni], ({isLoading}) => isTruthy(isLoading));

export const selectInfoTemplateManifestazione = createSelector([_self], (state) => {
  const { templateManifestazione, templateManifestazioneStatus, scommessaStatus } = state ?? {};
  return { templateManifestazione, templateManifestazioneStatus, scommessaStatus };
});

export const selectInfoTemplateAvvenimento = createSelector([_self], (state) => {
  const { templateAvvenimento, templateAvvenimentoStatus, avvenimentoFilter, scommessaStatus } = state ?? {};
  return { scommessaStatus, avvenimentoFilter, templateAvvenimento, templateAvvenimentoStatus };
});

export const selectIsTop = createSelector([_self], (state) => {
  const slug = Reflect.get(state, 'slug');
  return slug === 'sport';
});

export const selectDettaglioAvvenimento = createSelector([_self], ({ dettaglioAvvenimento }) => dettaglioAvvenimento);
export const selectAvvenimentoSlug = createSelector([selectDettaglioAvvenimento], (dettaglioAvvenimento) => {
  const { slug, slugDefaultScommessaGroup } = dettaglioAvvenimento ?? {};
  return isNotEmpty(slugDefaultScommessaGroup) ? slugDefaultScommessaGroup : slug;
});

export const selectScommessaStatus = createSelector([_self], (state) => state.scommessaStatus ?? ApiStatus.idle);

export const selectIsScommessaLoading = createSelector([selectScommessaStatus], (scommessaStatus) => {
  return scommessaStatus === ApiStatus.loading;
});

export const selectScommessaStatusFactory = () =>
  createSelector([selectScommessaStatus], (scommessaStatus) => scommessaStatus);

export const selectIsScommessaLoadingFactory = () =>
  createSelector([selectIsScommessaLoading], (isLoading) => isLoading);

export const selectScommessaResponseFactory = (path: string[]) => (state: RootState) =>
  dynamicDataLoader<RootState, ScommessaResponse>(state, path, true);

// TODO : MOVE TO LOCATION -- HERE IMPLEMENT selectCurrentSlug
export const selectCurrentPath = ({ sport: _, location }: RootState): string => {
  return location?.pathname;
};

export const selectTemplateSlug = ({ sport }: RootState) => sport?.templateSlug;

export const selectCurrentPathFactory = () => createSelector([selectCurrentPath], (result): string => result);

/* MAIN selectors */
export const selectDynamicTemplateFactory = (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) => {
  const contest = selectScommessa(state);

  const result = Reflect.get(contest ?? {}, 'template');
  return result;
};
export const selectDynamicSlugFactory = (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) => {
  const contest = selectScommessa(state);

  const result = Reflect.get(contest ?? {}, 'slug');
  return result;
};

export const selectEsitoMapFactory = (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) =>
  selectScommessa(state)?.esitoMap;

export const selectInfoEsitoMapFactory = (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) =>
  selectScommessa(state)?.infoEsitoMap;

export const selectInfoTipoScommessaMapFactory =
  (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) =>
    selectScommessa(state)?.infoTipoScommessaMap;

export const selectGroupScommessaMapFactory = (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) =>
  selectScommessa(state)?.infoTipoScommessaGroupMap;

export const selectScommessaMapFactory = (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) =>
  selectScommessa(state)?.scommessaMap;

export const selectAvvenimentoListFactory = (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) => {
  let { dettaglioAvvenimento, avvenimentoList } = selectScommessa(state) ?? {};
  if (!!dettaglioAvvenimento && !avvenimentoList?.some((x) => `${x?.key}` === `${dettaglioAvvenimento?.key}`)) {
    if (!Array.isArray(avvenimentoList)) {
      avvenimentoList = [];
    }

    avvenimentoList.unshift(dettaglioAvvenimento);
  }
  return avvenimentoList;
};

export const selectInfoAggiuntivaDataMapFactory =
  (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) =>
    selectScommessa(state)?.infoAggData;
export const selectInfoAggiuntivaMetaMapFactory =
  (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) =>
    selectScommessa(state)?.infoAggMeta;
export const selectInfoAggiuntivaIndexMapFactory =
  (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) =>
    selectScommessa(state)?.infoAggIndex;
export const selectInfoAggSummaryMapFactory = (selectScommessa: ScommessaResponseSelectorType) => (state: RootState) =>
  selectScommessa(state)?.infoAggSummaryMap;

export const selectInfoAggiuntivaBilanciataFactory =
  (t: (_k?: string, _f?: string) => string, selectScommessa: ScommessaResponseSelectorType, showHeader?: boolean) =>
  (state: RootState, scommessaKey: string): InfoAggiuntivaBilanciataType => {
    const km = new KeyManagerSport(scommessaKey);

    const getInfoAggiuntivaWeight = (
      esitoMap: EsitoMap,
      esitoKeyList: Array<string>
    ): { weight: number; hasActiveEsiti: boolean } => {
      let weight = 0;
      const wEsitoKeyList = (esitoKeyList ?? []).filter((x) => esitoMap?.[x]?.isActive);
      const hasActiveEsiti = isTruthy(wEsitoKeyList.length);

      if (hasActiveEsiti) {
        for (let index = wEsitoKeyList.length; index > 1; ) {
          index--;
          const p0 = esitoMap?.[wEsitoKeyList?.[index]]?.quota ?? 0;
          const p1 = esitoMap?.[wEsitoKeyList?.[index - 1]]?.quota ?? 0;
          weight += Math.abs(p0 - p1);
        }
      }

      if (esitoKeyList.length > 2) {
        return {
          weight: weight / (esitoKeyList.length - 1),
          hasActiveEsiti,
        };
      }
      return {
        weight,
        hasActiveEsiti,
      };
    };

    const getNext = (state: ScommessaResponse, key: string) => {
      // const path = ['infoAggIndex', key];
      // const indexData = dynamicDataLoader<ScommessaResponse, SportsInfoAggIndex>(state, path, true);
      // return indexData ?? ({} as SportsInfoAggIndex);
      return (state?.infoAggIndex?.[key] ?? {}) as SportsInfoAggIndex;
    };

    const getIsActive = (state: ScommessaResponse, key: string): boolean => {
      const km = new KeyManagerSport(key);
      if (key !== km.infoAggiuntivaKey) return true;

      // const path = ['infoAggData', key, 'isActive'];
      // const isActive = dynamicDataLoader<ScommessaResponse, boolean>(state, path, true);
      // return isTruthy(isActive);
      return isTruthy(state?.infoAggData?.[key]?.isActive);
    };

    const resolveInfoAggiuntivaTree = (
      state: ScommessaResponse,
      key: string,
      level: number = 0
    ): Array<{ key: string; weight: number; level: number; hasActiveEsiti: boolean }> => {
      let toSet = true;
      let list: Array<{ key: string; weight: number; level: number; hasActiveEsiti: boolean }> = [];
      level = level ?? 0;

      const isActive = getIsActive(state, key);

      if (isActive) {
        const { esitoMap, infoEsitoMap } = state;
        const { esitoKeyList, nextInfoAgg } = getNext(state, key);

        const childLvl = 1 + level;

        if ((esitoKeyList ?? []).length > 1) {
          // when esitoKeyList availabe - compute weight by quote gap
          const esitoMap2: Record<string, Esito> = {};
          const infoEsitoMap2: Record<string, SportsInfoEsito> = {};

          esitoKeyList?.forEach((x) => {
            const esito = Reflect.get(esitoMap ?? {}, x) ?? {};
            if (isNotEmpty(esito?.infoEsitoKey)) {
              infoEsitoMap2[esito.infoEsitoKey!] = infoEsitoMap?.[esito.infoEsitoKey!];
            }
            esitoMap2[x] = esito;
          });

          const sortedEsitoKeyList = sortEsiti({
            t,
            esitoKeyList,
            esitoMap: esitoMap2,
            infoEsitoMap: infoEsitoMap2,
            infoTipoScommessa,
          });

          let { weight, hasActiveEsiti } = getInfoAggiuntivaWeight(esitoMap ?? {}, sortedEsitoKeyList);

          list.push({ key, level, weight, hasActiveEsiti });
          toSet = false;
        } else if (isTruthy(nextInfoAgg?.length)) {
          // when nextInfoAgg availabe - compute weight as child weight media
          for (const infoAggiuntivaKey of nextInfoAgg!) {
            const tmp = resolveInfoAggiuntivaTree(state, infoAggiuntivaKey, childLvl);
            if (Array.isArray(tmp) && isTruthy(tmp?.length)) {
              list = list!.concat(tmp);
            }
          }

          list = list.filter((x) => x.level === childLvl);

          if (list.length > 0) {
            const weights = list.filter((x) => x.hasActiveEsiti).map((x) => x.weight);
            const weight = weights.reduce((acc, value) => acc + value, 0) / weights.length;
            const hasActiveEsiti = list.some((x) => x.hasActiveEsiti);

            list.push({ key, level, weight, hasActiveEsiti });
            toSet = false;
          }
        }
      }

      if (toSet) {
        list.push({ key, level, weight: 0, hasActiveEsiti: false });
      }

      // console.log(key, level, list);

      return list;
    };

    const scommessa = selectScommessa(state) ?? ({} as ScommessaResponse);

    const infoTipoScommessa: SportsInfoTipoScommessa = scommessa.infoTipoScommessaMap[km.tipoScommessaId];

    // console.log('------------------------')
    let list = resolveInfoAggiuntivaTree(scommessa, scommessaKey) ?? [];
    // console.dir(list)

    const self = list.find((x) => x.level === 0);

    const { defaultInfoAgg } = getNext(scommessa, `${self?.key}`);

    // get bilanciata previous mode
    // const [bilanced] = list.filter((x) => x.level === 1 && x.weight !== 0 && isTruthy(x.hasActiveEsiti)).sort((a, b) => a.weight - b.weight);

    // get first with weight > 0 as first option || first as fallback
    const sortedList = list
      .filter((x) => x.level === 1 && isTruthy(x.hasActiveEsiti))
      .sort((a, b) => a.weight - b.weight);
    let [bilanced] = [...sortedList.filter((x) => x.weight !== 0), ...sortedList];

    let nextInfoAgg = list.filter((x) => x.level === 1 && isTruthy(x.hasActiveEsiti)).map((x) => x.key);
    if (defaultInfoAgg && !isTruthy(nextInfoAgg.length)) {
      nextInfoAgg.unshift(defaultInfoAgg);
    }

    return {
      nextInfoAgg,
      bilanciataKey: bilanced?.key,
      defaultInfoAgg: defaultInfoAgg ?? bilanced?.key,
      isEveryQuoteDisabled: !isTruthy(self?.hasActiveEsiti),
      showHeader: isTruthy(showHeader),
    };
  };

interface scommessaActiveFactoryProps {
  keyList?: Array<string>;
  deepToEsiti?: boolean;
}
export const selectIsScommessaActiveFactory =
  (selectScommessa: ScommessaResponseSelectorType, { keyList, deepToEsiti }: scommessaActiveFactoryProps) =>
  (state: RootState) => {
    let result = false;

    if (isTruthy(keyList?.length)) {
      const scommessa = selectScommessa(state) ?? ({} as ScommessaResponse);

      const checkIsActive = (state: ScommessaResponse, key: string, deep: boolean): boolean => {
        // const ckActive = dynamicDataLoader<ScommessaResponse, boolean>(state, ['infoAggData', key, 'isActive'], true);
        const ckActive = state?.infoAggData?.[key]?.isActive;

        if (isMatch(`${ckActive}`, `^${false}$`)) {
          return false;
        }

        // const { esitoKeyList, nextInfoAgg } = dynamicDataLoader<ScommessaResponse, SportsInfoAggIndex>(state, ['infoAggIndex', key], true) ?? {};
        const { esitoKeyList, nextInfoAgg } = (state?.infoAggIndex?.[key] ?? {}) as SportsInfoAggIndex;

        const checks: Array<boolean> = [];
        if (isTruthy(nextInfoAgg?.length)) {
          (nextInfoAgg ?? []).forEach((x) => {
            checks.push(checkIsActive(state, x, deep));
          });
        } else if (deep && ckActive) {
          esitoKeyList?.forEach((esitoKey) => {
            // const { isActive, quota } = dynamicDataLoader<ScommessaResponse, Esito>(state, ['esitoMap', esitoKey], true) ?? {};
            const { isActive, quota } = (state?.esitoMap?.[esitoKey] ?? {}) as Esito;
            checks.push(isTruthy(isActive) && (quota ?? 0) > 0);
          });
        } else {
          checks.push(isTruthy(ckActive));
        }

        return checks.includes(true);
      };

      for (const infoAggKey of keyList ?? []) {
        result = checkIsActive(scommessa, infoAggKey, isTruthy(deepToEsiti));
        if (result) {
          break;
        }
      }
    }

    return result;
  };

export const selectSelectedFiltersFactory = () => (state: RootState) => {
  const selectedFilters = selectSelectedFilter(state) ?? {};
  const selectedFiltersLive = selectSelectedFilterLive(state) ?? {};
  return { selectedFilters, selectedFiltersLive };
};

export const selectInfoHeaderPlayer = createSelector([_self], (state) => {
  const betRadarInfo = Reflect.get(state.templateAvvenimento ?? {}, 'betRadarGiocatore') ?? {};
  const betRadarInfoNumber: Record<keyof SportsBetRadarGiocatoreDto, number> | {} = {};

  for (const key in betRadarInfo) {
    betRadarInfoNumber[key] = Number(betRadarInfo[key]) ?? 0;
  }

  return betRadarInfoNumber as Record<keyof SportsBetRadarGiocatoreDto, number>;
});

export const selectTabListByTemplate = createSelector(
  [(state: RootState, _: string) => _self(state), (_: RootState, templateName: string) => templateName],
  (state, templateName): Array<SportsPiuGiocateTabDto> =>
    (state?.[templateName]?.tabList ?? []).filter((x) => isNotEmpty(`${x?.idTab}`))
);

/* DETAILED selectors */
export const selectAvvenimentoFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [selectAvvenimentoListFactory(selectScommessa), (_state: RootState, avvenimentoKey: string) => avvenimentoKey],
    (avvenimentoList, avvenimentoKey) =>
      (avvenimentoList ?? [])?.find((avvenimento) => `${avvenimento?.key}` === `${avvenimentoKey}`)
  );

export const selectAvvenimentiFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      selectAvvenimentoListFactory(selectScommessa),
      (_state: RootState, avvenimentiKey: Array<string>) => avvenimentiKey,
    ],
    (avvenimentoList, avvenimentiKey) => avvenimentoList?.filter(({ key }) => !!key && avvenimentiKey.includes(key))
  );

export const selectCompetitorsFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [selectAvvenimentoFactory(selectScommessa)],
    (avvenimento) => {
      const result: Array<SportsCompetitor> = [];
      const { firstCompetitor, secondCompetitor, descrizione, descrizioneTrKey } = avvenimento ?? {};
      if (!!firstCompetitor && !!secondCompetitor) {
        result.push(firstCompetitor);
        result.push(secondCompetitor);
      } else {
        result.push({
          descrizione,
          descrizioneTrKey,
        } as SportsCompetitor);
      }
      return result;
    },
    {
      memoizeOptions: { resultEqualityCheck: shallowEqual },
    }
  );

export const selectCompetitorFactory = (selectScommessa: ScommessaResponseSelectorType, competitor: 1 | 2) =>
  createSelector([selectAvvenimentoFactory(selectScommessa)], (avvenimento) =>
    competitor === 1 ? avvenimento?.firstCompetitor : avvenimento?.secondCompetitor
  );

export const selectPlayersFactory = (
  selectScommessa: ScommessaResponseSelectorType,
  infoTipoScommessaKeys: Array<string>
) =>
  createSelector(
    [
      selectAvvenimentoListFactory(selectScommessa),
      selectInfoAggiuntivaDataMapFactory(selectScommessa),
      selectInfoAggiuntivaIndexMapFactory(selectScommessa),
      selectSelectedAvvenimento,
    ],
    (avvenimentoList, infoAggDataMap, infoAggIndexMap, selectedAvvenimento) => {
      const avvenimento = avvenimentoList?.find((avv) => `${selectedAvvenimento?.avvenimentoKey}` === `${avv?.key}`);
      let playerKeys: Array<string> = [];
      if (avvenimento) {
        const competitor = (
          selectedAvvenimento?.competitor === 1
            ? avvenimento?.firstCompetitor ?? {}
            : avvenimento?.secondCompetitor ?? {}
        )?.descrizione;

        infoTipoScommessaKeys.forEach((infoTipoScommessaId) => {
          const { scommessaKey } = new KeyManagerSport(`${selectedAvvenimento?.avvenimentoKey}-${infoTipoScommessaId}`);
          const scommessa = infoAggIndexMap?.[scommessaKey];

          if (scommessa && !!scommessa.nextInfoAgg?.length) {
            const competitorInfoAggKey = scommessa.nextInfoAgg.find(
              (key) => infoAggDataMap?.[key].valore?.toUpperCase() === competitor?.toUpperCase()
            );

            if (competitorInfoAggKey) {
              playerKeys = infoAggIndexMap?.[competitorInfoAggKey]?.nextInfoAgg ?? [];
            }
          }
        });
        return playerKeys;
      }
      return [];
    }
  );

export const selectIsScommessaMarcatoreFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector([selectScommessa], (scommessa) => scommessa?.isMarcatore);

export const selectEsitoFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [selectEsitoMapFactory(selectScommessa), (_state: RootState, esitoId: string) => esitoId],
    (esitoMap, esitoId) => esitoMap?.[esitoId]
  );

export const selectInfoEsitoFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [selectInfoEsitoMapFactory(selectScommessa), (_state: RootState, keyInfoEsito: string) => keyInfoEsito],
    (infoEsitoMap, keyInfoEsito) => infoEsitoMap?.[keyInfoEsito]
  );

export const selectInfoTipoScommessaFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      selectInfoTipoScommessaMapFactory(selectScommessa),
      (_state: RootState, infoTipoScommessaKey: string) => infoTipoScommessaKey,
    ],
    (infoTipoScommessaMap, infoTipoScommessaKey) => infoTipoScommessaMap?.[infoTipoScommessaKey]
  );

export const selectInfoGroupScommessaFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [selectGroupScommessaMapFactory(selectScommessa), (_state: RootState, groupKey: string) => groupKey],
    (infoTipoGroupMap, groupKey: string) => infoTipoGroupMap?.[groupKey]
  );

export const selectInfoTipoScommessaListFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      (state: RootState) => selectScommessa(state)?.infoTipoScommessaMap,
      (state: RootState) => selectScommessa(state)?.infoTipoScommessaGroupMap,
      (state: RootState, infoTipoScommessaKeyList: Array<string>) => infoTipoScommessaKeyList,
    ],
    (infoTipoScommessaMap, infoTipoScommessaGroupMap, infoTipoScommessaKeyList) =>
      infoTipoScommessaMap
        ? infoTipoScommessaKeyList?.map((infoTipoScommessaKey) => {
            // Caso normale
            if (!isMatch(infoTipoScommessaKey, '^group-')) {
              return Reflect.get(infoTipoScommessaMap ?? {}, infoTipoScommessaKey);
            }
            if (!infoTipoScommessaGroupMap) {
              appInsight?.trackException({
                error: new Error('Unable to found group map'),
                properties: {
                  infoTipoScommessaGroupMap,
                },
              });
              return;
            }

            // Caso gruppo, viene costruita un'infoTipoScommessa mergiando info dei singoli tipi e del gruppo
            let groupInfo = Reflect.get(infoTipoScommessaGroupMap ?? {}, infoTipoScommessaKey);

            /* PAS : description and tooltip could be loaded, trKeys not
          if(!groupInfo) {
            const singleKeys = `${infoTipoScommessaKey}`.replaceAll('group-', '').split('_') ?? [];
            const singleTipos = singleKeys.map((k) => infoTipoScommessaMap?.[k]).filter(Boolean);
            const toolTip = getPrefix(singleTipos.map(x => x.toolTip))
            const descrizione = getPrefix(singleTipos.map(x => x?.descrizione))
            groupInfo = {toolTip, descrizione, key:infoTipoScommessaKey} as SportsInfoTipoScommessaGroupDto
          }
          */

            const singleKeys = infoTipoScommessaKey.replaceAll('group-', '').split('_') ?? [];
            const singleTipos = singleKeys.map((k) => infoTipoScommessaMap[k]).filter(Boolean);

            // TODO : DANGEROUS CASTING
            const infoTipoScommessa = isTruthy(singleTipos?.length)
              ? ({
                  ...singleTipos[0],
                  tipoVisualizzazione: 1, // ?
                  key: infoTipoScommessaKey,
                  numeroEsiti: singleTipos?.length,
                  toolTip: groupInfo?.toolTip,
                  toolTipTrKey: groupInfo?.toolTipTrKey,
                  headers: singleTipos.map((el) => el?.headers?.[0]),
                  headersTrKey: singleTipos.map((el) => el?.headersTrKey?.[0]),
                  descrizione: groupInfo?.descrizione,
                  descrizioneTrKey: groupInfo?.descrizioneTrKey,
                } as InfoTipoScommessa)
              : undefined;
            return infoTipoScommessa;
          })
        : []
  );

export const selectInfoAggiuntivaDataFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      (_state: RootState, infoAggiuntivaKey: string) => infoAggiuntivaKey,
      selectInfoAggiuntivaDataMapFactory(selectScommessa),
    ],
    (key, data): SportsInfoAggData => Reflect.get(data ?? {}, key)
  );
export const selectInfoAggiuntivaIndexFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      (_state: RootState, infoAggiuntivaKey: string) => infoAggiuntivaKey,
      selectInfoAggiuntivaIndexMapFactory(selectScommessa),
    ],
    (key, data): SportsInfoAggIndex => Reflect.get(data ?? {}, key)
  );

export const selectInfoAggiuntivaMetaFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      (_state: RootState, infoAggiuntivaKey: string) => infoAggiuntivaKey,
      selectInfoAggiuntivaMetaMapFactory(selectScommessa),
    ],
    (key, data): SportsInfoAggMeta => Reflect.get(data ?? {}, key)
  );

export const selectInfoAggiuntivaBilanciata = (
  t: (_a?: string, _b?: string) => string,
  selectScommessa: ScommessaResponseSelectorType,
  showsHeader?: boolean
) =>
  createSelector(
    [(state: RootState) => state, (_state: RootState, infoAggiuntivaKey: string) => infoAggiuntivaKey],
    (state, infoAggiuntivaKey) =>
      selectInfoAggiuntivaBilanciataFactory(t, selectScommessa, showsHeader)(state, infoAggiuntivaKey),
    {
      memoizeOptions: {
        resultEqualityCheck: shallowEqual,
      },
    }
  );

export const selectScommessaFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [selectScommessaMapFactory(selectScommessa), (_state: RootState, scommessaKey: string) => scommessaKey],
    (scommessaMap, scommessaKey) => scommessaMap?.[scommessaKey]
  );

export const selectEsitoByScommessaFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      selectEsitoMapFactory(selectScommessa),
      selectInfoAggiuntivaIndexMapFactory(selectScommessa),
      (_state: RootState, infoAggKey: string) => infoAggKey,
    ],
    (esitoMap, infoAggMapIndex, infoAggKey) => {
      const infoAggIndex = Reflect.get(infoAggMapIndex ?? {}, infoAggKey);
      if (isTruthy(infoAggIndex?.esitoKeyList?.length)) {
        return infoAggIndex?.esitoKeyList?.map((esitoKey) => Reflect.get(esitoMap ?? {}, esitoKey));
      }
      return [];
    },
    {
      memoizeOptions: {
        resultEqualityCheck: deepEqual,
      },
    }
  );

export const selectInfoAggiuntivaSummaryFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      (_state: RootState, infoAggiuntivaKey: string) => infoAggiuntivaKey,
      selectInfoAggSummaryMapFactory(selectScommessa),
    ],
    (key, data) => Reflect.get(data ?? {}, key)
  );

export const selectDescrizioniTipoScommessaFactory = (selectScommessa: ScommessaResponseSelectorType) =>
  createSelector(
    [
      selectGroupScommessaMapFactory(selectScommessa),
      selectInfoTipoScommessaMapFactory(selectScommessa),
      (_state: RootState, keys: Array<string | number>) => keys,
    ],
    (infoTipoGroupMap, infoTipoScommessaMap, keys): Array<TipoScommessaDescrizioniType> =>
      (keys ?? [])
        .map((infoTipoScommessaKey) => {
          if (isMatch(String(infoTipoScommessaKey), '^group-')) {
            const groupInfo = Reflect.get(infoTipoGroupMap ?? {}, infoTipoScommessaKey);

            return groupInfo as TipoScommessaDescrizioniType;
          } else {
            const { descrizione, descrizioneTrKey } =
              Reflect.get(infoTipoScommessaMap ?? {}, infoTipoScommessaKey) ?? {};
            return { descrizione, descrizioneTrKey } as TipoScommessaDescrizioniType;
          }
        })
        .filter((item) => {
          const result = purgeNulls(item);
          // console.log(`purgeNulls`, item, result)
          return result !== undefined;
        }),
    {
      memoizeOptions: {
        resultEqualityCheck: shallowEqual,
      },
    }
  );

export const selectIsFilterChanged = createSelector(
  [selectCurrentPath, selectSelectedFilter, selectSelectedFilterLive, selectAvvenimentoFilter],
  (path, sportFilters, livefilters) => {
    if (isMatch(path, `^(/*)${AppFragment.Live}/`)) {
      return deepEqual(livefilters, filtersLiveDefault) ? false : true;
    }

    const { quota, orario, isResetted } = filtersDefault;
    return deepEqual(sportFilters, { quota, orario, isResetted }) ? false : true;
  }
);

export const selectTabListResponseFactory =
  (path: string[]) =>
  (state: RootState): ProssimiEventiStateType | undefined =>
    dynamicDataLoader<RootState, ProssimiEventiStateType>(state, path, true);
/* TODO: senza trasformare i modelli,
ProssimiEventiStateType potrebbe essere =
  SportsScommesseProssimiEventiDto |
  SportsScommesseDirettissimeDto |
  SportsScommesseTopAntepostDto |
  SportsScommesseInEvidenzaDto |
  SportsScommesseSnaiRunnerDto
pulito, safe e autoaggiornante
 */

export const selectTabListFactory = (selectStateType: TabListResponseSelectorType) =>
  createSelector([selectStateType], (data): Array<SportsPiuGiocateTabDto> => {
    const { avvenimentoList, tabList } = data ?? {};
    if (isTruthy(avvenimentoList?.length)) return tabList ?? [];
    return [];
  });
