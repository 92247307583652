import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import {
  UsersBaseRuleItemDtoFavoriteReqDto,
  UsersManifestazioneRuleItemDto,
  UsersUserDataSectionEnum,
} from 'types/swagger';

import { ApiEndpointQuery } from '@reduxjs/toolkit/dist/query/core/module';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { TreeFavouritesResponseApi } from 'lib/api/treeFavouritesResponseApi';
import { apiSlice } from 'features/api/apiSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import format from 'date-fns/format';
import { RootState } from 'lib/centralStore';
import { setIsOpenLoginModalValue } from 'features/modal/modalSlice';

export type LazyGetTriggerType =
  | Function
  | LazyQueryTrigger<
      QueryDefinition<
        void,
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
        never,
        TreeFavouritesResponseApi,
        'api'
      >
    >;

export interface SlotToggleFavouriteType {
  id: string;
  verticale: string;
  isFavorite: boolean;
  idProgramma?: string;
  idAvvenimento?: string;
  triggerRefetch?: LazyGetTriggerType;
}

export const SlotToggleFavourite = createAsyncThunk(
  'SlotToggleFavourite',
  async (props: SlotToggleFavouriteType, { dispatch }): Promise<SlotToggleFavouriteType> => {
    const { id, verticale, triggerRefetch, isFavorite } = props ?? {};
    const { getSlots, getCasino, getCardGames } = apiSlice.endpoints;
    const rule = {
      id,
      dateTimeSaved: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    } as UsersManifestazioneRuleItemDto;
    const payload: UsersBaseRuleItemDtoFavoriteReqDto = {
      rule,
      verticale: UsersUserDataSectionEnum.FAVORITE_SLOT,
    };
    let onSuccess: ApiEndpointQuery<any, any> = getSlots;
    switch (verticale.toLowerCase()) {
      case 'casino': {
        onSuccess = getCasino;
        payload.verticale = UsersUserDataSectionEnum.FAVORITE_CASINO;
        break;
      }
      case 'giochi': {
        onSuccess = getCardGames;
        payload.verticale = UsersUserDataSectionEnum.FAVORITE_CARDGAME;
        break;
      }
      case 'ippica': {
        payload.verticale = UsersUserDataSectionEnum.FAVORITE_IPPICA_CAVALLO;
        break;
      }
      case 'favorite_sport_squadra': {
        payload.verticale = UsersUserDataSectionEnum.FAVORITE_SPORT_SQUADRA;
        break;
      }
    }

    try {
      if (isFavorite) await dispatch(apiSlice.endpoints.deleteFavouriteBySection.initiate(payload));
      else await dispatch(apiSlice.endpoints.postFavouriteBySection.initiate(payload));

      if (typeof triggerRefetch === 'function') {
        await triggerRefetch();
      } else {
        await dispatch(onSuccess.initiate(undefined, { forceRefetch: true }));
      }

      return Promise.resolve(props);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const getFantawinnerGameUrl = createAsyncThunk('getFantawinnerGameUrl', async (_, { getState, dispatch }) => {
  const state = getState() as RootState;

  if (state.auth.user?.name) {
    return await dispatch(
      apiSlice.endpoints.getUrlLaunchGame.initiate({
        username: state.auth.user.name,
        gameCodeName: process.env.NEXT_PUBLIC_FANTAWINNER_GAME_CODE_NAME,
        codPiattaforma: 152,
        playMode: 1,
        modalita: '',
      })
    ).unwrap();
  }

  dispatch(setIsOpenLoginModalValue(true));
  return {};
});
