/************************************************************************************************************
      DO NOT ADD COMPONENTS IN THIS FILE
      ADD YOUR .SVG IN \common\tasks\image-lib\src FOLDER
      THEN RUN npm run image-lib COMMAND
  ************************************************************************************************************/
import React from 'react';

/* eslint-disable no-unused-vars */
export enum IconsEnum {
  'STARS' = 'stars',
  'VOUCHER_DOCUMENT' = 'voucher-document',
  'VOUCHER_LOGO' = 'voucher-logo',
  'VOUCHER_USER' = 'voucher-user',
  'SNAI_NO_LOGO' = 'snai-no-logo',
  'BACKGROUND' = 'background',
  'MATCH' = 'match',
  'GAME_SLASH' = 'game-slash',
  'TRASH_CAN' = 'trash-can',
  'AUTOESCLUSION_24H' = 'autoesclusion-24h',
  'PROGRAMMED' = 'programmed',
  'USER' = 'user',
  'GOTO_ARROW' = 'goto-arrow',
  'ROUND_QUESTION' = 'round-question',
  'ARCHIVE' = 'archive',
  'DOWNLOAD_APPLE' = 'download-apple',
  'FERRI_DAVANTI' = 'ferri-davanti',
  'FERRI_DIETRO' = 'ferri-dietro',
  'DISCIPLINA_128' = 'disciplina-128',
  'DISCIPLINA_159_1' = 'disciplina-159-1',
  'LEGENDA_INATTESA_DARK' = 'legenda-inattesa-dark',
  'LEGENDA_CHIUSA_DARK' = 'legenda-chiusa-dark',
  'LEGENDA_CHIUSA_LIGHT' = 'legenda-chiusa-light',
  'LEGENDA_INPAGAMENTO_DARK' = 'legenda-inpagamento-dark',
  'LEGENDA_INATTESA_LIGHT' = 'legenda-inattesa-light',
  'LEGENDA_APERTA_DARK' = 'legenda-aperta-dark',
  'LEGENDA_APERTA_LIGHT' = 'legenda-aperta-light',
  'LEGENDA_INPAGAMENTO_LIGHT' = 'legenda-inpagamento-light',
  'SPID_LOGO' = 'spid-logo',
  'SMART_TOY' = 'smart-toy',
  'SIMPLEMESSAGE_SUCCESS' = 'simplemessage-success',
  'LINK_EXTERNAL' = 'link-external',
  'PLAY_STROKE' = 'play-stroke',
  'STATS' = 'stats',
  'EXCLAMATION_CIRCLE' = 'exclamation-circle',
  'TROPHY' = 'trophy',
  'RIBBON' = 'ribbon',
  'PICCHE' = 'picche',
  'QUADRI' = 'quadri',
  'PENCIL' = 'pencil',
  'FULL_PIN' = 'full-pin',
  'HEART_OUTLINE' = 'heart-outline',
  'INSTANT_PANEL' = 'instant-panel',
  'PREFERRED_OFF' = 'preferred-off',
  'STAR_YELLOW' = 'star-yellow',
  'IMAGE' = 'image',
  'PLAYER' = 'player',
  'POINT_HAMBURGER' = 'point-hamburger',
  'PINLOCATION' = 'pinlocation',
  'SIMPLEMESSAGE_INFORMATION' = 'simplemessage-information',
  'QUESTIONS' = 'questions',
  'FULL_PINNED' = 'full-pinned',
  'NEW_LIGHT' = 'new-light',
  'SNAI_RUNNER' = 'snai-runner',
  'PIN' = 'pin',
  'DOWNLOAD' = 'download',
  'SHIELD' = 'shield',
  'STREAMING' = 'streaming',
  'SEARCH' = 'search',
  'NEW_DARK' = 'new-dark',
  'INFO_SOLID' = 'info-solid',
  'ECLAMATION_MARK_OUTLINE' = 'eclamation-mark-outline',
  'LIVE_HB' = 'live_hb',
  'SIMPLEMESSAGE_WARNING' = 'simplemessage-warning',
  'TICKET' = 'ticket',
  'SLOTS' = 'slots',
  'LIVE' = 'live',
  'SEARCH_OFF' = 'search-off',
  'CHEWRON_UP' = 'chewron-up',
  'RELOAD_SPIN' = 'reload-spin',
  'MESSAGE' = 'message',
  'CUP' = 'cup',
  'PLUS_CIRCLE' = 'plus-circle',
  'LAPTOP_MAC' = 'laptop-mac',
  'MENU' = 'menu',
  'LIST_MENU' = 'list-menu',
  'MEDIA' = 'media',
  'SIMPLEMESSAGE_CRITICAL' = 'simplemessage-critical',
  'DASHBOARD' = 'dashboard',
  'PREFERRED_OFF_BCK' = 'preferred-off-bck',
  'MINUS_CIRCLE' = 'minus-circle',
  'STADIA_CONTROLLER' = 'stadia-controller',
  'DUPLICATE' = 'duplicate',
  'INFO' = 'info',
  'MEDAL' = 'medal',
  'VECTOR' = 'vector',
  'PATTINAGGIO_VELOCITA' = 'pattinaggio-velocita',
  'TV' = 'tv',
  'SHARE' = 'share',
  'TRIP' = 'trip',
  'FORM' = 'form',
  'SIMPLEMESSAGE_ERROR' = 'simplemessage-error',
  'STAR_OUTLINE' = 'star-outline',
  'LIGHT_MODE' = 'light-mode',
  'PRINTER' = 'printer',
  'MATCH_LIVE' = 'match-live',
  'GIFT' = 'gift',
  'STAR' = 'star',
  'SUCCESS' = 'success',
  'RADIO' = 'radio',
  'PIG' = 'pig',
  'FILTER_VERTICAL' = 'filter-vertical',
  'SMARTPHONE' = 'smartphone',
  'ARROW_UP' = 'arrow-up',
  'ROUND_QUESTION_32' = 'round-question-32',
  'PLUS' = 'plus',
  'CHEWRON_DOWN' = 'chewron-down',
  'PREFERRED_ON' = 'preferred-on',
  'ARROW_DECREASE_LIGHT' = 'arrow-decrease-light',
  'ALERT' = 'alert',
  'CANCEL' = 'cancel',
  'DOUBLE_CHEVRON_RIGHT' = 'double-chevron-right',
  'CHART' = 'chart',
  'CALCULATOR_MD' = 'calculator-md',
  'CHEWRON_LEFT' = 'chewron-left',
  'DICES' = 'dices',
  'CANCEL_FULL' = 'cancel-full',
  'THREE_DOTS' = 'three-dots',
  'EYE_OFF' = 'eye-off',
  'GAME_AUTOEXCLUSION' = 'game-autoexclusion',
  'GEAR' = 'gear',
  'CLOSE' = 'close',
  'DASHBOARD_UPDATED' = 'dashboard-updated',
  'FILTER' = 'filter',
  'CHEWRON_RIGHT' = 'chewron-right',
  'CONTROLLER_OFF' = 'controller-off',
  'MINUS' = 'minus',
  'CIRCLE_WARNING' = 'circle-warning',
  'HAPPYBET_LOGO' = 'happybet-logo',
  'DEPOSIT' = 'deposit',
  'EXIT' = 'exit',
  'CHECK_GREEN' = 'check-green',
  'CHRISTMAS' = 'christmas',
  'CALCULATOR' = 'calculator',
  'CALENDAR' = 'calendar',
  'AWESOME_USER' = 'awesome-user',
  'FIORI' = 'fiori',
  'COMMENT' = 'comment',
  'EYE' = 'eye',
  'ARROW_INCREASE_LIGHT' = 'arrow-increase-light',
  'CHECKMARK' = 'checkmark',
  'BONUS_GREY' = 'bonus-grey',
  'INFO_YELLOW' = 'info-yellow',
  'CHAT' = 'chat',
  'CUORI' = 'cuori',
  'ARROW' = 'arrow',
  'BACKSPACE' = 'backspace',
  'GALOPPO' = 'galoppo',
  'BONUS_GOLD' = 'bonus-gold',
  'DARK_MODE' = 'dark-mode',
  'CASH_MULTIPLE' = 'cash-multiple',
  'ARROW_INCREASE' = 'arrow-increase',
  'CONTRACT_PDF' = 'contract-pdf',
  'CHECK' = 'check',
  'ACME' = 'acme',
  'AVATAR' = 'avatar',
  'ARROW_BACK' = 'arrow-back',
  'DOWNLOAD_VIRTUAL' = 'download-virtual',
  'CLOSE_CIRCLE' = 'close-circle',
  'ACCOUNT' = 'account',
  'BONUS' = 'bonus',
  'ARROW_DECREASE' = 'arrow-decrease',
  'ADM_WHITE_HORIZONTAL' = 'adm-white-horizontal',
  'ADM_WHITE_18' = 'adm-white-18',
  'ADM_COLORFUL_HORIZONTAL' = 'adm-colorful-horizontal',
  'FERRI_FULL' = 'ferri-full',
  'FERRI_NONE' = 'ferri-none',
  'NAZIONE_IT' = 'nazione-it',
  'SNAI_PICTO' = 'snai-picto',
  'SNAI_LOGO' = 'snai-logo',
  'SNAI_LOGO_SHORT' = 'snai-logo-short',
  'NAZIONE_UK' = 'nazione-uk',
  'LEGENDA_TRIS_LIGHT' = 'legenda-tris-light',
  'LEGENDA_TRIS_DARK' = 'legenda-tris-dark',
  'LEGENDA_TOT_DARK' = 'legenda-tot-dark',
  'LEGENDA_TQQ_LIGHT' = 'legenda-tqq-light',
  'LEGENDA_ANNULLATA_DARK' = 'legenda-annullata-dark',
  'LEGENDA_TQQ_DARK' = 'legenda-tqq-dark',
  'LEGENDA_ANNULLATA_LIGHT' = 'legenda-annullata-light',
  'LEGENDA_TOT_LIGHT' = 'legenda-tot-light',
  'NAZIONE_SA' = 'nazione-sa',
  'NAZIONE_BR' = 'nazione-br',
  'NAZIONE_AR' = 'nazione-ar',
  'NAZIONE_BH' = 'nazione-bh',
  'NAZIONE_FI' = 'nazione-fi',
  'NAZIONE_CA' = 'nazione-ca',
  'NAZIONE_CO' = 'nazione-co',
  'NAZIONE_PH' = 'nazione-ph',
  'NAZIONE_BB' = 'nazione-bb',
  'NAZIONE_KE' = 'nazione-ke',
  'NAZIONE_US' = 'nazione-us',
  'NAZIONE_FR' = 'nazione-fr',
  'NAZIONE_DK' = 'nazione-dk',
  'NAZIONE_IE' = 'nazione-ie',
  'NAZIONE_KR' = 'nazione-kr',
  'NAZIONE_AG' = 'nazione-ag',
  'NAZIONE_AU' = 'nazione-au',
  'NAZIONE_CL' = 'nazione-cl',
  'NAZIONE_GB' = 'nazione-gb',
  'NAZIONE_BE' = 'nazione-be',
  'NAZIONE_AE' = 'nazione-ae',
  'NAZIONE_JP' = 'nazione-jp',
  'NAZIONE_HR' = 'nazione-hr',
  'NAZIONE_CY' = 'nazione-cy',
  'NAZIONE_DE' = 'nazione-de',
  'NAZIONE_ES' = 'nazione-es',
  'NAZIONE_AT' = 'nazione-at',
  'NAZIONE_LB' = 'nazione-lb',
  'NAZIONE_EE' = 'nazione-ee',
  'NAZIONE_JM' = 'nazione-jm',
  'NAZIONE_GR' = 'nazione-gr',
  'NAZIONE_IN' = 'nazione-in',
  'NAZIONE_AZ' = 'nazione-az',
  'NAZIONE_CN' = 'nazione-cn',
  'METEO_NEBBIA' = 'meteo-nebbia',
  'METEO_COPERTO' = 'meteo-coperto',
  'METEO_VENTO' = 'meteo-vento',
  'METEO_FOSCHIA' = 'meteo-foschia',
  'METEO_PIOGGIA' = 'meteo-pioggia',
  'METEO_BELLO' = 'meteo-bello',
  'METEO_VARIABILE' = 'meteo-variabile',
  'CAVALLO' = 'cavallo',
  'TROTTO' = 'trotto',
  'CAVALLO_PARAOCCHI' = 'cavallo-paraocchi',
  'DISCIPLINA_67' = 'disciplina-67',
  'DISCIPLINA_03' = 'disciplina-03',
  'DISCIPLINA_83' = 'disciplina-83',
  'DISCIPLINA_107' = 'disciplina-107',
  'DISCIPLINA_01' = 'disciplina-01',
  'DISCIPLINA_119' = 'disciplina-119',
  'DISCIPLINA_10' = 'disciplina-10',
  'DISCIPLINA_134' = 'disciplina-134',
  'DISCIPLINA_104' = 'disciplina-104',
  'DISCIPLINA_126' = 'disciplina-126',
  'DISCIPLINA_97' = 'disciplina-97',
  'DISCIPLINA_70' = 'disciplina-70',
  'DISCIPLINA_117' = 'disciplina-117',
  'DISCIPLINA_158' = 'disciplina-158',
  'DISCIPLINA_135' = 'disciplina-135',
  'DISCIPLINA_47' = 'disciplina-47',
  'DISCIPLINA_109' = 'disciplina-109',
  'DISCIPLINA_34' = 'disciplina-34',
  'DISCIPLINA_130' = 'disciplina-130',
  'DISCIPLINA_75' = 'disciplina-75',
  'DISCIPLINA_7' = 'disciplina-7',
  'DISCIPLINA_112' = 'disciplina-112',
  'DISCIPLINA_131' = 'disciplina-131',
  'DISCIPLINA_86' = 'disciplina-86',
  'DISCIPLINA_140' = 'disciplina-140',
  'DISCIPLINA_65' = 'disciplina-65',
  'DISCIPLINA_1001' = 'disciplina-1001',
  'DISCIPLINA_121' = 'disciplina-121',
  'DISCIPLINA_TIRO_CON_VOLO' = 'disciplina-tiro-con-volo',
  'DISCIPLINA_ESPORTS_FORMULA_E' = 'disciplina-esports-formula-e',
  'DISCIPLINA_106' = 'disciplina-106',
  'DISCIPLINA_124' = 'disciplina-124',
  'DISCIPLINA_1027' = 'disciplina-1027',
  'DISCIPLINA_30' = 'disciplina-30',
  'DISCIPLINA_1026' = 'disciplina-1026',
  'DISCIPLINA_92' = 'disciplina-92',
  'DISCIPLINA_129' = 'disciplina-129',
  'DISCIPLINA_118' = 'disciplina-118',
  'DISCIPLINA_HOCKEY_INLIINE' = 'disciplina-hockey-inliine',
  'DISCIPLINA_61' = 'disciplina-61',
  'DISCIPLINA_142' = 'disciplina-142',
  'DISCIPLINA_59' = 'disciplina-59',
  'DISCIPLINA_115' = 'disciplina-115',
  'DISCIPLINA_46' = 'disciplina-46',
  'DISCIPLINA_156' = 'disciplina-156',
  'DISCIPLINA_148' = 'disciplina-148',
  'DISCIPLINA_21' = 'disciplina-21',
  'DISCIPLINA_90' = 'disciplina-90',
  'DISCIPLINA_94' = 'disciplina-94',
  'DISCIPLINA_152' = 'disciplina-152',
  'DISCIPLINA_87' = 'disciplina-87',
  'DISCIPLINA_88' = 'disciplina-88',
  'DISCIPLINA_125' = 'disciplina-125',
  'DISCIPLINA_123' = 'disciplina-123',
  'DISCIPLINA_20' = 'disciplina-20',
  'DISCIPLINA_SPORT_GAELICI' = 'disciplina-sport-gaelici',
  'DISCIPLINA_MANIFESTAZIONI_SPETTACOLO' = 'disciplina-manifestazioni-spettacolo',
  'DISCIPLINA_ESPORTS_STARCRAFT_2' = 'disciplina-esports-starcraft-2',
  'DISCIPLINA_89' = 'disciplina-89',
  'DISCIPLINA_73' = 'disciplina-73',
  'DISCIPLINA_91' = 'disciplina-91',
  'DISCIPLINA_77' = 'disciplina-77',
  'DISCIPLINA_147' = 'disciplina-147',
  'DISCIPLINA_64' = 'disciplina-64',
  'DISCIPLINA_108' = 'disciplina-108',
  'DISCIPLINA_136' = 'disciplina-136',
  'DISCIPLINA_143' = 'disciplina-143',
  'DISCIPLINA_157' = 'disciplina-157',
  'DISCIPLINA_98' = 'disciplina-98',
  'DISCIPLINA_80' = 'disciplina-80',
  'DISCIPLINA_1013' = 'disciplina-1013',
  'DISCIPLINA_99' = 'disciplina-99',
  'DISCIPLINA_154' = 'disciplina-154',
  'DISCIPLINA_ESPORTS_NBA2K_LEAGUE' = 'disciplina-esports-nba2k-league',
  'DISCIPLINA_114' = 'disciplina-114',
  'DISCIPLINA_1' = 'disciplina-1',
  'DISCIPLINA_71' = 'disciplina-71',
  'DISCIPLINA_150' = 'disciplina-150',
  'DISCIPLINA_96' = 'disciplina-96',
  'DISCIPLINA_41' = 'disciplina-41',
  'DISCIPLINA_1028' = 'disciplina-1028',
  'DISCIPLINA_100' = 'disciplina-100',
  'DISCIPLINA_62' = 'disciplina-62',
  'DISCIPLINA_132' = 'disciplina-132',
  'DISCIPLINA_FALLBACK' = 'disciplina-fallback',
  'DISCIPLINA_40' = 'disciplina-40',
  'DISCIPLINA_69' = 'disciplina-69',
  'DISCIPLINA_110' = 'disciplina-110',
  'DISCIPLINA_02' = 'disciplina-02',
  'DISCIPLINA_1003' = 'disciplina-1003',
  'DISCIPLINA_66' = 'disciplina-66',
  'DISCIPLINA_105' = 'disciplina-105',
  'DISCIPLINA_85' = 'disciplina-85',
  'DISCIPLINA_PATTINAGGIO_FIGURATO' = 'disciplina-pattinaggio-figurato',
  'DISCIPLINA_82' = 'disciplina-82',
  'DISCIPLINA_22' = 'disciplina-22',
  'DISCIPLINA_39' = 'disciplina-39',
  'DISCIPLINA_ESPORTS_MOTOCICLISMO' = 'disciplina-esports-motociclismo',
  'DISCIPLINA_138' = 'disciplina-138',
  'DISCIPLINA_55' = 'disciplina-55',
  'DISCIPLINA_155' = 'disciplina-155',
  'DISCIPLINA_1004' = 'disciplina-1004',
  'DISCIPLINA_1007' = 'disciplina-1007',
  'DISCIPLINA_120' = 'disciplina-120',
  'DISCIPLINA_29' = 'disciplina-29',
  'DISCIPLINA_28' = 'disciplina-28',
  'DISCIPLINA_57' = 'disciplina-57',
  'DISCIPLINA_53' = 'disciplina-53',
  'DISCIPLINA_93' = 'disciplina-93',
  'DISCIPLINA_48' = 'disciplina-48',
  'DISCIPLINA_18' = 'disciplina-18',
  'DISCIPLINA_60' = 'disciplina-60',
  'DISCIPLINA_19' = 'disciplina-19',
  'DISCIPLINA_103' = 'disciplina-103',
  'DISCIPLINA_36' = 'disciplina-36',
  'DISCIPLINA_16' = 'disciplina-16',
  'DISCIPLINA_15' = 'disciplina-15',
  'DISCIPLINA_27' = 'disciplina-27',
  'DISCIPLINA_49' = 'disciplina-49',
  'DISCIPLINA_95' = 'disciplina-95',
  'DISCIPLINA_54' = 'disciplina-54',
  'DISCIPLINA_81' = 'disciplina-81',
  'DISCIPLINA_43' = 'disciplina-43',
  'DISCIPLINA_23' = 'disciplina-23',
  'DISCIPLINA_79' = 'disciplina-79',
  'DISCIPLINA_003' = 'disciplina-003',
  'DISCIPLINA_17' = 'disciplina-17',
  'DISCIPLINA_3' = 'disciplina-3',
  'DISCIPLINA_68' = 'disciplina-68',
  'DISCIPLINA_42' = 'disciplina-42',
  'DISCIPLINA_37' = 'disciplina-37',
  'DISCIPLINA_127' = 'disciplina-127',
  'DISCIPLINA_38' = 'disciplina-38',
  'DISCIPLINA_58' = 'disciplina-58',
  'DISCIPLINA_51' = 'disciplina-51',
  'DISCIPLINA_102' = 'disciplina-102',
  'DISCIPLINA_001' = 'disciplina-001',
  'DISCIPLINA_44' = 'disciplina-44',
  'DISCIPLINA_2' = 'disciplina-2',
  'DISCIPLINA_116' = 'disciplina-116',
  'DISCIPLINA_08' = 'disciplina-08',
  'DISCIPLINA_6' = 'disciplina-6',
  'DISCIPLINA_144' = 'disciplina-144',
  'DISCIPLINA_12' = 'disciplina-12',
  'DISCIPLINA_8' = 'disciplina-8',
  'DISCIPLINA_52' = 'disciplina-52',
  'DISCIPLINA_ESPORTS_WORLD_OF_WARCRAFT' = 'disciplina-esports-world-of-warcraft',
  'DISCIPLINA_78' = 'disciplina-78',
  'DISCIPLINA_101' = 'disciplina-101',
  'DISCIPLINA_9' = 'disciplina-9',
  'DISCIPLINA_45' = 'disciplina-45',
  'DISCIPLINA_24' = 'disciplina-24',
  'DISCIPLINA_76' = 'disciplina-76',
  'DISCIPLINA_14' = 'disciplina-14',
  'DISCIPLINA_00' = 'disciplina-00',
  'DISCIPLINA_13' = 'disciplina-13',
  'DISCIPLINA_35' = 'disciplina-35',
  'DISCIPLINA_33' = 'disciplina-33',
  'DISCIPLINA_56' = 'disciplina-56',
  'DISCIPLINA_31' = 'disciplina-31',
  'DISCIPLINA_50' = 'disciplina-50',
  'DISCIPLINA_32' = 'disciplina-32',
  'DISCIPLINA_5' = 'disciplina-5',
  'DISCIPLINA_11' = 'disciplina-11',
  'MESSAGE_CENTER_ICO_241' = 'message-center-ico-241',
  'MESSAGE_CENTER_ICO_404' = 'message-center-ico-404',
  'MESSAGE_CENTER_ICO_387' = 'message-center-ico-387',
  'MESSAGE_CENTER_ICO_371' = 'message-center-ico-371',
  'MESSAGE_CENTER_ICO_244' = 'message-center-ico-244',
  'MESSAGE_CENTER_ICO_291' = 'message-center-ico-291',
  'MESSAGE_CENTER_ICO_366' = 'message-center-ico-366',
  'MESSAGE_CENTER_ICO_225' = 'message-center-ico-225',
  'MESSAGE_CENTER_ICO_271' = 'message-center-ico-271',
  'MESSAGE_CENTER_ICO_375' = 'message-center-ico-375',
  'MESSAGE_CENTER_ICO_158' = 'message-center-ico-158',
  'MESSAGE_CENTER_ICO_53' = 'message-center-ico-53',
  'MESSAGE_CENTER_ICO_313' = 'message-center-ico-313',
  'MESSAGE_CENTER_ICO_319' = 'message-center-ico-319',
  'MESSAGE_CENTER_ICO_380' = 'message-center-ico-380',
  'MESSAGE_CENTER_ICO_201' = 'message-center-ico-201',
  'MESSAGE_CENTER_ICO_288' = 'message-center-ico-288',
  'MESSAGE_CENTER_ICO_314' = 'message-center-ico-314',
  'MESSAGE_CENTER_ICO_389' = 'message-center-ico-389',
  'MESSAGE_CENTER_ICO_397' = 'message-center-ico-397',
  'MESSAGE_CENTER_ICO_411' = 'message-center-ico-411',
  'MESSAGE_CENTER_ICO_395' = 'message-center-ico-395',
  'MESSAGE_CENTER_ICO_403' = 'message-center-ico-403',
  'MESSAGE_CENTER_ICO_381' = 'message-center-ico-381',
  'MESSAGE_CENTER_ICO_159' = 'message-center-ico-159',
  'MESSAGE_CENTER_ICO_416' = 'message-center-ico-416',
  'MESSAGE_CENTER_ICO_399' = 'message-center-ico-399',
  'MESSAGE_CENTER_ICO_386' = 'message-center-ico-386',
  'MESSAGE_CENTER_ICO_167' = 'message-center-ico-167',
  'MESSAGE_CENTER_ICO_406' = 'message-center-ico-406',
  'MESSAGE_CENTER_ICO_235' = 'message-center-ico-235',
  'MESSAGE_CENTER_ICO_421' = 'message-center-ico-421',
  'MESSAGE_CENTER_ICO_398' = 'message-center-ico-398',
  'MESSAGE_CENTER_ICO_376' = 'message-center-ico-376',
  'MESSAGE_CENTER_ICO_152' = 'message-center-ico-152',
  'MESSAGE_CENTER_ICO_217' = 'message-center-ico-217',
  'MESSAGE_CENTER_ICO_401' = 'message-center-ico-401',
  'MESSAGE_CENTER_ICO_329' = 'message-center-ico-329',
  'MESSAGE_CENTER_ICO_308' = 'message-center-ico-308',
  'MESSAGE_CENTER_ICO_413' = 'message-center-ico-413',
  'MESSAGE_CENTER_ICO_74' = 'message-center-ico-74',
  'MESSAGE_CENTER_ICO_129' = 'message-center-ico-129',
  'MESSAGE_CENTER_ICO_415' = 'message-center-ico-415',
  'MESSAGE_CENTER_ICO_372' = 'message-center-ico-372',
  'MESSAGE_CENTER_ICO_87' = 'message-center-ico-87',
  'MESSAGE_CENTER_ICO_140' = 'message-center-ico-140',
  'MESSAGE_CENTER_ICO_190' = 'message-center-ico-190',
  'MESSAGE_CENTER_ICO_43' = 'message-center-ico-43',
  'MESSAGE_CENTER_ICO_186' = 'message-center-ico-186',
  'MESSAGE_CENTER_ICO_363' = 'message-center-ico-363',
  'MESSAGE_CENTER_ICO_79' = 'message-center-ico-79',
  'MESSAGE_CENTER_ICO_400' = 'message-center-ico-400',
  'MESSAGE_CENTER_ICO_402' = 'message-center-ico-402',
  'MESSAGE_CENTER_ICO_317' = 'message-center-ico-317',
  'MESSAGE_CENTER_ICO_394' = 'message-center-ico-394',
  'MESSAGE_CENTER_ICO_419' = 'message-center-ico-419',
  'MESSAGE_CENTER_ICO_222' = 'message-center-ico-222',
  'MESSAGE_CENTER_ICO_180' = 'message-center-ico-180',
  'MESSAGE_CENTER_ICO_368' = 'message-center-ico-368',
  'MESSAGE_CENTER_ICO_417' = 'message-center-ico-417',
  'MESSAGE_CENTER_ICO_392' = 'message-center-ico-392',
  'MESSAGE_CENTER_ICO_408' = 'message-center-ico-408',
  'MESSAGE_CENTER_ICO_407' = 'message-center-ico-407',
  'MESSAGE_CENTER_ICO_412' = 'message-center-ico-412',
  'MESSAGE_CENTER_ICO_362' = 'message-center-ico-362',
  'MESSAGE_CENTER_ICO_390' = 'message-center-ico-390',
  'MESSAGE_CENTER_ICO_374' = 'message-center-ico-374',
  'MESSAGE_CENTER_ICO_298' = 'message-center-ico-298',
  'MESSAGE_CENTER_ICO_28' = 'message-center-ico-28',
  'MESSAGE_CENTER_ICO_303' = 'message-center-ico-303',
  'MESSAGE_CENTER_ICO_230' = 'message-center-ico-230',
  'MESSAGE_CENTER_ICO_418' = 'message-center-ico-418',
  'MESSAGE_CENTER_ICO_294' = 'message-center-ico-294',
  'MESSAGE_CENTER_ICO_369' = 'message-center-ico-369',
  'MESSAGE_CENTER_ICO_365' = 'message-center-ico-365',
  'MESSAGE_CENTER_ICO_393' = 'message-center-ico-393',
  'MESSAGE_CENTER_ICO_61' = 'message-center-ico-61',
  'MESSAGE_CENTER_ICO_420' = 'message-center-ico-420',
  'MESSAGE_CENTER_ICO_396' = 'message-center-ico-396',
  'MESSAGE_CENTER_ICO_373' = 'message-center-ico-373',
  'MESSAGE_CENTER_ICO_264' = 'message-center-ico-264',
  'MESSAGE_CENTER_ICO_385' = 'message-center-ico-385',
  'MESSAGE_CENTER_ICO_391' = 'message-center-ico-391',
  'MESSAGE_CENTER_ICO_367' = 'message-center-ico-367',
  'MESSAGE_CENTER_ICO_377' = 'message-center-ico-377',
  'MESSAGE_CENTER_ICO_151' = 'message-center-ico-151',
  'MESSAGE_CENTER_ICO_409' = 'message-center-ico-409',
  'MESSAGE_CENTER_ICO_379' = 'message-center-ico-379',
  'MESSAGE_CENTER_ICO_245' = 'message-center-ico-245',
  'MESSAGE_CENTER_ICO_405' = 'message-center-ico-405',
  'MESSAGE_CENTER_ICO_388' = 'message-center-ico-388',
  'MESSAGE_CENTER_ICO_414' = 'message-center-ico-414',
  'MESSAGE_CENTER_ICO_248' = 'message-center-ico-248',
  'MESSAGE_CENTER_ICO_259' = 'message-center-ico-259',
  'MESSAGE_CENTER_ICO_71' = 'message-center-ico-71',
  'MESSAGE_CENTER_ICO_40' = 'message-center-ico-40',
  'MESSAGE_CENTER_ICO_205' = 'message-center-ico-205',
  'MESSAGE_CENTER_ICO_301' = 'message-center-ico-301',
  'MESSAGE_CENTER_ICO_141' = 'message-center-ico-141',
  'MESSAGE_CENTER_ICO_120' = 'message-center-ico-120',
  'MESSAGE_CENTER_ICO_25' = 'message-center-ico-25',
  'MESSAGE_CENTER_ICO_21' = 'message-center-ico-21',
  'MESSAGE_CENTER_ICO_364' = 'message-center-ico-364',
  'MESSAGE_CENTER_ICO_210' = 'message-center-ico-210',
  'MESSAGE_CENTER_ICO_215' = 'message-center-ico-215',
  'MESSAGE_CENTER_ICO_196' = 'message-center-ico-196',
  'MESSAGE_CENTER_ICO_52' = 'message-center-ico-52',
  'MESSAGE_CENTER_ICO_98' = 'message-center-ico-98',
  'MESSAGE_CENTER_ICO_384' = 'message-center-ico-384',
  'MESSAGE_CENTER_ICO_382' = 'message-center-ico-382',
  'MESSAGE_CENTER_ICO_76' = 'message-center-ico-76',
  'MESSAGE_CENTER_ICO_57' = 'message-center-ico-57',
  'MESSAGE_CENTER_ICO_163' = 'message-center-ico-163',
  'MESSAGE_CENTER_ICO_188' = 'message-center-ico-188',
  'MESSAGE_CENTER_ICO_283' = 'message-center-ico-283',
  'MESSAGE_CENTER_ICO_102' = 'message-center-ico-102',
  'MESSAGE_CENTER_ICO_173' = 'message-center-ico-173',
  'MESSAGE_CENTER_ICO_82' = 'message-center-ico-82',
  'MESSAGE_CENTER_ICO_101' = 'message-center-ico-101',
  'MESSAGE_CENTER_ICO_254' = 'message-center-ico-254',
  'MESSAGE_CENTER_ICO_146' = 'message-center-ico-146',
  'MESSAGE_CENTER_ICO_49' = 'message-center-ico-49',
  'MESSAGE_CENTER_ICO_134' = 'message-center-ico-134',
  'MESSAGE_CENTER_ICO_85' = 'message-center-ico-85',
  'MESSAGE_CENTER_ICO_109' = 'message-center-ico-109',
  'MESSAGE_CENTER_ICO_370' = 'message-center-ico-370',
  'MESSAGE_CENTER_ICO_42' = 'message-center-ico-42',
  'MESSAGE_CENTER_ICO_8' = 'message-center-ico-8',
  'MESSAGE_CENTER_ICO_309' = 'message-center-ico-309',
  'MESSAGE_CENTER_ICO_410' = 'message-center-ico-410',
  'MESSAGE_CENTER_ICO_199' = 'message-center-ico-199',
  'MESSAGE_CENTER_ICO_238' = 'message-center-ico-238',
  'MESSAGE_CENTER_ICO_12' = 'message-center-ico-12',
  'MESSAGE_CENTER_ICO_383' = 'message-center-ico-383',
  'MESSAGE_CENTER_ICO_276' = 'message-center-ico-276',
  'MESSAGE_CENTER_ICO_73' = 'message-center-ico-73',
  'MESSAGE_CENTER_ICO_147' = 'message-center-ico-147',
  'MESSAGE_CENTER_ICO_55' = 'message-center-ico-55',
  'MESSAGE_CENTER_ICO_69' = 'message-center-ico-69',
  'MESSAGE_CENTER_ICO_91' = 'message-center-ico-91',
  'MESSAGE_CENTER_ICO_47' = 'message-center-ico-47',
  'MESSAGE_CENTER_ICO_378' = 'message-center-ico-378',
  'MESSAGE_CENTER_ICO_59' = 'message-center-ico-59',
  'MESSAGE_CENTER_ICO_11' = 'message-center-ico-11',
  'MESSAGE_CENTER_ICO_252' = 'message-center-ico-252',
  'MESSAGE_CENTER_ICO_17' = 'message-center-ico-17',
  'MESSAGE_CENTER_ICO_36' = 'message-center-ico-36',
  'MESSAGE_CENTER_ICO_105' = 'message-center-ico-105',
  'MESSAGE_CENTER_ICO_63' = 'message-center-ico-63',
  'MESSAGE_CENTER_ICO_41' = 'message-center-ico-41',
  'MESSAGE_CENTER_ICO_208' = 'message-center-ico-208',
}

export type IconsType = `${IconsEnum}`;

/* eslint-enable no-unused-vars */

const capitalize = (input: string): string => {
  const segments = (input ?? '').split('');
  if (Array.isArray(segments) && segments.length > 0) {
    segments[0] = segments[0].toUpperCase();
  }
  return segments.join('');
};

export const camelize = (input: string): string => {
  return (input ?? '')
    .split(/\.| |-|@/gim)
    .map((x) => capitalize(x))
    .join('');
};

export const toIconsEnum = (raw: string): IconsEnum => {
  const value = (raw ?? '').replace(/-/gim, '_').trim();
  const key = Object.keys(IconsEnum).find((x) => x.toLowerCase() === value.toLowerCase());
  if (key) {
    return Reflect.get(IconsEnum, key);
  }
  const fallback = (raw ?? '').replace(/_/gim, '-').trim().toLowerCase();
  return IconsEnum[fallback as keyof typeof IconsEnum];
};

export interface IconProps extends React.SVGProps<SVGSVGElement | HTMLImageElement> {
  iconId: string | IconsType;
}

export { Icon } from 'components/Icons/ZZ-Loader.tsx';
