import { SportsMenuDisciplineWithTranslationsDto, sportsCli } from 'common/types/swagger';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { feedLiveNav } from './actions';
import { isClientSide } from 'utility/functions';
import { useAppDispatch } from 'lib/centralStore';
import { useRouter } from 'next/router';

const isSSR = isClientSide() ? false : true;
const myEffect = isSSR ? useEffect : useLayoutEffect;

export const useLiveNavSnapshot = (data?: SportsMenuDisciplineWithTranslationsDto): Promise<void> => {
  const dispatch = useAppDispatch();
  const { isReady, asPath } = useRouter();
  const [isCompleted, setCompleted] = useState(false);

  const lastSlug = useRef<string>('');
  const menuData = useRef<SportsMenuDisciplineWithTranslationsDto>(
    (data ?? {}) as SportsMenuDisciplineWithTranslationsDto
  );

  const doFetch = useCallback(() => {
    sportsCli.menumanifestazionilive
      .getmenuList()
      .then((result) => {
        if (result?.ok) {
          menuData.current = result.data;
        }
      })
      .finally(() => {
        setCompleted(true);
      });
  }, []);

  if (isSSR) {
    return Promise.resolve();
  }

  myEffect(() => {
    if (lastSlug.current !== asPath) {
      lastSlug.current = `${asPath}`;
      doFetch();
    }
  }, [asPath, doFetch]);

  myEffect(() => {
    if (isReady && isCompleted) {
      // once HYDRATION and FETCH both completed -> FEED with fresh data
      setCompleted(false);
      dispatch(feedLiveNav(menuData.current));
    }
  }, [isReady, isCompleted]);

  return Promise.resolve();
};
