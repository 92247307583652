import {
  CartCancellaPrenotazioneRequestDto,
  CartVenditaResponseDto,
  SportsAvvenimentoEsposto,
  SportsCacheScommesseCoreWithTranslationsDto,
  SportsCacheScommesseMarcatoriWithTranslationsDto,
  SportsStreamingProviderEnum,
  SportsStreamingResponseDto,
  SportsTipoScommessa,
  SportsTopVinciteDto,
  UsersUserDataSectionEnum,
} from 'types/swagger';
import { EsitoMap, ScommessaResponse } from 'lib/api/sport/sportScommesseBySlugResponse';
import { SportsBetRadarGiocatoreDto, SportsSquadraItem, SportsWidgetPiuGiocateType } from 'types/swagger/sports';
import { appInsight, appInsightScommessaReady } from 'components/appInsight';
import {
  initScommessaResponse,
  initTemplateDirettissime,
  initTemplateSnaiRunner,
  setIsMarcatore,
  setSlug,
} from 'features/sport/sportSlice';
import { isLoadingVenditaChanged, venditaAlertFailedOpenChange } from 'features/carrello/carrelloSlice';

import { AddManifestazioneToPreferitiPayload } from 'features/sport/components/sportNav/sportNavAccordion/components/sportNavAccordionContent/SportNavAccordionContent';
import { ApiStatus } from './thunkUtils';
import { CartBonusResponse } from 'lib/api/sport/cartBonusResponseApi';
import { DirettissimeResponseApi } from 'lib/api/sport/direttissimeResponseApi';
import { SnaiRunnerResponseApi } from 'lib/api/sport/snaiRunnerResponseApi';
import { SportLiveStreamingRequestApi } from 'lib/api/sport/sportLiveStreamingRequestApi';
import { SportNewsResponseApi } from 'lib/api/sport/sportNewsResponseApi';
import { SportPrenotaScommessaInitSessionResponse } from 'lib/api/sport/sportPrenotaScommessaInitSessionApi';
import { SportPrenotaScommessaResponse } from 'lib/api/sport/sportPrenotaScommessaApi';
import { SportScommesseImpostazioni } from 'lib/api/sport/sportScommesseImpostazioniApi';
import { SportTicket } from 'features/sport/components/sportTicket/utils/calcoloCarrello/types';
import { SviluppoSistemiResponse } from 'lib/api/sport/sportSviluppoSistemiResponseApi';
import { VirtualTicket } from 'features/virtual/components/virtualTicket/utils/calcoloCarrello/types';
import { apiSlice } from './apiSlice';
import { bonusConfigurationAdded } from 'features/sport/components/sportTicket/sportTicketSlice';
import { feedLingUI } from 'hooks/useLingUI';
import { isSnaiSite } from 'utility/constant';
import { venditaSport } from 'features/sport/components/sportTicket/sportTicketActions';
import { SportPiuGiocateResponseApi } from 'lib/api/sport/sportPiuGiocateResponseApi';

const scommesseEndPoint = '/sports/sports/scommesse';
const venditaEndPointSnai = `/cart/vendita`;
const venditaEndPointHappybet = `/cart/hbet/vendita`;

export interface TemplateLiveParams {
  slug: string;
  filters?: Record<string, Array<string>>;
}

export const sportApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getScommesseMarcatoreBySlug: build.query<
      SportsCacheScommesseMarcatoriWithTranslationsDto,
      { slug: string; slugPlayer?: string }
    >({
      query: ({ slug, slugPlayer }) => {
        const params = new URLSearchParams();
        params.append('slug', slug);
        if (slugPlayer) {
          params.append('slugGiocatore', slugPlayer);
        }
        return `/sports/sports/scommesse-marcatori?${params.toString()}`;
      },
      async onQueryStarted({ slug }, { queryFulfilled, dispatch }) {
        let isFailure = false;
        try {
          dispatch(initScommessaResponse({ status: ApiStatus.loading, data: { slug } as any }));
          const { data } = await queryFulfilled;

          const { traduzioneMap, ...result } = data;
          feedLingUI(traduzioneMap);

          dispatch(
            initScommessaResponse({ data: { ...result, slug } as unknown as ScommessaResponse, status: ApiStatus.idle })
          );
          dispatch(setSlug(slug));
        } catch (exception) {
          isFailure = true;
          dispatch(initScommessaResponse({ status: ApiStatus.failed, data: { slug } as any }));
          // console.error(`${scommesseEndPoint}?slug=${arg}`, exception);
          appInsight?.trackException({ exception, id: `${scommesseEndPoint}?slug=${decodeURI(slug)}` });
        } finally {
          setTimeout(() => {
            appInsightScommessaReady(isFailure);
          }, 0);
          dispatch(setIsMarcatore(true));
        }
      },
    }),
    getStatsMarcatori: build.query<SportsBetRadarGiocatoreDto, { idManifestazione: string; slug: string }>({
      query: ({ idManifestazione, slug }) =>
        `/sports/sports/statistiche-marcatori?manifKey=${idManifestazione}&slug=${slug}`,
    }),
    getScommessePreMatch: build.query<SportsCacheScommesseCoreWithTranslationsDto, string>({
      query: (slug) => `${scommesseEndPoint}?slug=${slug?.toLowerCase()}`,
      keepUnusedDataFor: 0,
      async onQueryStarted(slug, { queryFulfilled, dispatch }) {
        let isError = false;
        try {
          dispatch(initScommessaResponse({ status: ApiStatus.loading, data: { slug } as any }));

          const { data } = await queryFulfilled;

          const { traduzioneMap, ...result } = data;
          feedLingUI(traduzioneMap);

          dispatch(
            initScommessaResponse({ data: { ...result, slug } as unknown as ScommessaResponse, status: ApiStatus.idle })
          );
          dispatch(setSlug(slug));
        } catch (exception) {
          isError = true;
          dispatch(initScommessaResponse({ status: ApiStatus.failed, data: { slug } as any }));
          // console.error(`${scommesseEndPoint}?slug=${arg}`, exception);
          appInsight?.trackException({ exception, id: `${scommesseEndPoint}?slug=${decodeURI(slug)}` });
        } finally {
          setTimeout(() => {
            appInsightScommessaReady(isError);
          }, 0);
        }
      },
    }),
    getAvvenimento: build.query<SportsCacheScommesseCoreWithTranslationsDto, string>({
      query: (slug) => `${scommesseEndPoint}?slug=${slug}`,
      keepUnusedDataFor: 0,
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const { traduzioneMap } = data;
          feedLingUI(traduzioneMap);
        } catch (exception) {
          appInsight?.trackException({ exception, id: `${scommesseEndPoint}?slug=${decodeURI(arg)}` });
        }
      },
    }),
    addManifestazioneToPreferiti: build.mutation<any, AddManifestazioneToPreferitiPayload>({
      query: ({ id, slug, descrizione }) => ({
        url: `users/user/favorites/sports/manifestazione`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: {
          rule: {
            id,
            slug,
            descrizione,
          },
          verticale: UsersUserDataSectionEnum.FAVORITE_SPORT_MANIFESTAZIONE,
        },
      }),
      invalidatesTags: ['Manifestazioni'],
    }),
    getSearchTipiScommessa: build.query<Array<SportsTipoScommessa>, { slug: string; searchValue: string }>({
      query: ({ slug, searchValue }) => `/sports/sports/search-tipi-scommessa?slug=${slug}&word=${searchValue}`,
      keepUnusedDataFor: 0.01,
    }),
    scommettiSingolaMultiplaSport: build.mutation<CartVenditaResponseDto, SportTicket>({
      query: (sportTicket) => ({
        url: `${isSnaiSite ? venditaEndPointSnai : venditaEndPointHappybet}/sport-singola-multipla`,
        method: 'POST',
        // Include the entire post object as the body of the request
        body: sportTicket,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        dispatch(isLoadingVenditaChanged(true));
        try {
          const { data } = await queryFulfilled;
          dispatch(isLoadingVenditaChanged(false));
          await dispatch(venditaSport(data));
        } catch (err) {
          // `onError` side-effect
          // console.error('Failed vendita: ', err);
          dispatch(isLoadingVenditaChanged(false));
          dispatch(venditaAlertFailedOpenChange(true));
        }
      },
    }),
    scommettiSistemaSport: build.mutation<CartVenditaResponseDto, SportTicket>({
      query: (sportTicket) => {
        let { prezzo, puntataPerScommessa, ...scommessa } = sportTicket ?? {};
        if (isSnaiSite) {
          try {
            const tmp = structuredClone(puntataPerScommessa);
            Object.entries(puntataPerScommessa).forEach(([key, value]) => {
              Reflect.set(tmp, key, Math.floor(value * 100));
            });
            puntataPerScommessa = tmp;
          } catch (error) {
            console.error('Errore durante il ciclo forEach:', error);
          }
        }
        const newTicket: SportTicket = {
          ...scommessa,
          puntataPerScommessa,
          prezzo: Math.floor(prezzo),
        };
        return {
          url: `${isSnaiSite ? venditaEndPointSnai : venditaEndPointHappybet}/sport-sistema`,
          method: 'POST',
          // Include the entire post object as the body of the request
          body: newTicket,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        dispatch(isLoadingVenditaChanged(true));
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          await dispatch(venditaSport(data));
          dispatch(isLoadingVenditaChanged(false));
        } catch (err) {
          // `onError` side-effect
          // console.error('Failed vendita: ', err);
          dispatch(isLoadingVenditaChanged(false));
          dispatch(venditaAlertFailedOpenChange(true));
        }
      },
    }),
    prenotaScommessaInitSession: build.mutation<SportPrenotaScommessaInitSessionResponse, void>({
      query: () => ({
        url: `/cart/prenotazione/init-session`,
        method: 'POST',
        // Include the entire post object as the body of the request
        body: {},
      }),
    }),
    prenotaScommessa: build.mutation<
      SportPrenotaScommessaResponse,
      { codiceSessione: string } & (SportTicket | VirtualTicket)
    >({
      query: (ticketPrenotato) => ({
        url: `/cart/prenotazione/prenota-scommessa`,
        method: 'POST',
        // Include the entire post object as the body of the request
        body: ticketPrenotato,
      }),
    }),
    cancellaPrenotazione: build.mutation<
      {
        statusCodeResponse: number;
        errore: string;
      },
      CartCancellaPrenotazioneRequestDto
    >({
      query: (ticketPrenotato) => ({
        url: `/cart/prenotazione/cancella-prenotazione`,
        method: 'POST',
        // Include the entire post object as the body of the request
        body: ticketPrenotato,
      }),
    }),
    getImpostazioniScommessa: build.query<SportScommesseImpostazioni, void>({
      query: () => '/cart/vendita/impostazioni-scommesse',
      providesTags: ['ImpostazioniScommessa'],
    }),
    updateImpostazioniScommessa: build.mutation<
      {
        statusCodeResponse: number;
        errore: string;
      },
      SportScommesseImpostazioni
    >({
      query: (body) => ({
        url: '/cart/vendita/impostazioni-scommesse',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['ImpostazioniScommessa'],
    }),
    runSistemiSimulatore: build.mutation({
      query: (initialPost) => ({
        url: `${process.env.NEXT_PUBLIC_SPORT_SIMULATORE_BASE_URL}`,
        method: 'POST',
        // Include the entire post object as the body of the request
        body: initialPost,
      }),
    }),
    getLiveStreaming: build.query<SportsStreamingResponseDto, SportLiveStreamingRequestApi>({
      query: (body) => ({
        method: 'POST',
        url: `/sports/live/streaming`,
        body,
      }),
      transformResponse: async (response: SportsStreamingResponseDto) => {
        if (response.type === SportsStreamingProviderEnum.Img) {
          // console.log(response);
          if (!response.url) {
            appInsight?.trackException({
              exception: new Error('IMG PROVIDER WITHOUT ANY URL'),
              id: 'img-provider-without-any-url',
              properties: {
                response,
              },
            });
            throw new Error('IMG PROVIDER WITHOUT ANY URL');
          }
          const imgResponse: { hlsUrl: string; statusCode: number; eventId: number } = await (
            await fetch(response.url)
          ).json();

          if (imgResponse.statusCode !== 200) {
            throw imgResponse;
          }

          const formattedResponse: SportsStreamingResponseDto = {
            dataorarisposta: Date.now().toString(),
            descrizione: '',
            esito: 1024,
            type: SportsStreamingProviderEnum.Img,
            url: imgResponse.hlsUrl,
          };
          return formattedResponse;
        }
        return response;
      },
    }),
    getSharedTicket: build.query<SportTicket, { idTicket: string }>({
      query: ({ idTicket }) => ({
        url: `/cart/prenotazione/ticket-condiviso`,
        method: 'POST',
        // Include the entire post object as the body of the request
        body: {
          idTicket,
          fisico: 0,
        },
      }),
    }),
    sviluppoSistemiVincite: build.mutation<SviluppoSistemiResponse, SportTicket>({
      query: (body) => ({
        url: '/cart/vendita/sviluppo-sistemi',
        method: 'POST',
        body: body,
      }),
    }),
    updateQuoteBySportTicketAvvenimentoList: build.mutation<
      {
        esitoMap: EsitoMap;
        avvenimentiSospesi: {
          [key: string]: boolean;
        };
        infoAggiuntiveSospese: {
          [key: string]: boolean;
        };
      },
      {
        [key: string]: boolean;
      }
    >({
      query: (body) => ({
        url: '/sports/sports/cart-update',
        method: 'POST',
        body: body,
      }),
    }),
    getCartBonus: build.query<CartBonusResponse, void>({
      query: () => `/users/user/bonus-carrello-sport-offline`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(bonusConfigurationAdded(data));
        } catch (err) {
          // `onError` side-effect
          console.log(err);
        }
      },
    }),
    getGuardaSuSnai: build.query<{ avvenimentoList: Array<SportsAvvenimentoEsposto> }, void>({
      query: () => `/sports/sports/guarda-su-snai`,
      transformResponse: async (avvenimentoList: Array<SportsAvvenimentoEsposto>) => {
        return { avvenimentoList };
      },
    }),
    getDirettissime: build.query<DirettissimeResponseApi, string>({
      query: (slug) => `/sports/sports/direttissime?slug=${slug?.toLowerCase()}`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          if (data) {
            dispatch(
              initTemplateDirettissime({
                data: {
                  direttissime: data.direttissime,
                  ...data.scommesse,
                },
              })
            );
          }
        } catch (err) {
          // `onError` side-effect
          console.log(err);
        }
      },
    }),
    getSportNews: build.query<SportNewsResponseApi, undefined | string>({
      query: (slug) => (slug === undefined ? `/sports/sports/news` : `/sports/sports/news?slug=${slug}`),
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
    }),
    getTopVincite: build.query<SportsTopVinciteDto, void>({
      query: () => `/sports/sports/top-vincite`,
    }),
    getSnaiRunner: build.query<SnaiRunnerResponseApi, void>({
      query: () => `/sports/sports/snai-runner`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          if (data) {
            dispatch(
              initTemplateSnaiRunner({
                data: {
                  tabList: data.tabList,
                  ...(data.scommesse as unknown as ScommessaResponse),
                },
              })
            );
          }
        } catch (err) {
          // `onError` side-effect
          console.log(err);
        }
      },
    }),
    getTeams: build.query<Array<SportsSquadraItem>, string>({
      query: (slug) => (!slug ? `/sports/sports/squadre` : `/sports/sports/squadre?slug=${slug.toLowerCase()}`),
    }),
    getPiuGiocatePerCategoria: build.query<
      SportPiuGiocateResponseApi,
      { sport: string; type: SportsWidgetPiuGiocateType }
    >({
      query: ({ sport, type }) => `/sports/sports/widget-piu-giocate?slug=sport/${sport}&type=${type}`,
    }),
  }),
  overrideExisting: false,
});

export const {
  usePrefetch,
  useGetTeamsQuery,
  useGetSportNewsQuery,
  useLazyGetTeamsQuery,
  useGetSnaiRunnerQuery,
  useGetTopVinciteQuery,
  useGetGuardaSuSnaiQuery,
  useGetDirettissimeQuery,
  useGetSharedTicketQuery,
  useGetLiveStreamingQuery,
  useLazyGetCartBonusQuery,
  useLazyGetSportNewsQuery,
  useGetStatsMarcatoriQuery,
  usePrenotaScommessaMutation,
  useLazyGetStatsMarcatoriQuery,
  useGetSearchTipiScommessaQuery,
  useCancellaPrenotazioneMutation,
  useLazyGetScommessePreMatchQuery,
  useScommettiSistemaSportMutation,
  useGetImpostazioniScommessaQuery,
  useSviluppoSistemiVinciteMutation,
  useLazyGetSearchTipiScommessaQuery,
  useGetScommesseMarcatoreBySlugQuery,
  usePrenotaScommessaInitSessionMutation,
  useUpdateImpostazioniScommessaMutation,
  useLazyGetScommesseMarcatoreBySlugQuery,
  useAddManifestazioneToPreferitiMutation,
  useScommettiSingolaMultiplaSportMutation,
  useUpdateQuoteBySportTicketAvvenimentoListMutation,
  useGetPiuGiocatePerCategoriaQuery,
} = sportApi;

export const { getScommessePreMatch } = sportApi.endpoints;
