import {
  SportsAvvenimentoEsposto,
  SportsCacheScommesseCoreDto,
  SportsEsito,
  SportsInfoEsito,
  SportsInfoTipoScommessa,
  SportsInfoTipoScommessaGroupDto,
  SportsScommessaMapDto,
} from 'types/swagger';

import { ApiStatusType } from 'features/api/thunkUtils';

/* eslint-disable no-unused-vars */
export enum SizeInfoAggiuntiva {
  Large = 'large',
  Small = 'small',
}

export enum VisualizationEnum {
  Antepost = 3,
  EsitiOverThree = 2,
  EsitiUnderThree = 1,
  RecursiveInfoAgg = 7,
  SingleInfoAggEsitiOverThree = 5,
  SingleInfoAggEsitiUnderThree = 4,
  MultipleInfoAggEsitiUnderThree = 6,
}
/* eslint-enable no-unused-vars */

/**
 * Opaque/Tagged types utilities
 */
declare const tag: unique symbol;

export type TagContainer<Token> = {
  readonly [tag]: Token;
};

export type Opaque<Type, Token = unknown> = Type & TagContainer<Token>;

export type UnwrapOpaque<OpaqueType extends TagContainer<unknown>> = OpaqueType extends Opaque<
  infer Type,
  OpaqueType[typeof tag]
>
  ? Type
  : OpaqueType;

export type KeyAvvenimento = Opaque<string, 'KeyAvvenimento'>;

export type scommessaKey = Opaque<string, 'scommessaKey'>;

export type KeyTipo = Opaque<string, 'KeyTipo'>;

export type KeyEsito = Opaque<`${string}-${string}-${string}-${string}-${string}`, 'KeyEsito'>;

export type KeyInfoAgg = Opaque<string, 'KeyInfoAgg'>;

export type Scommessa = SportsScommessaMapDto & {
  isClosed: boolean;
};

export type ScommessaMap = {
  [key: string]: Scommessa;
};

export interface Esito extends SportsEsito {
  idEsito: string;
  idAvvenimento: number;
  idTipoScommessa: number;
}

export type EsitoMap = {
  [key: string]: Esito;
};

export type InfoTipoScommessaMap = {
  [key: string]: InfoTipoScommessa;
};

export type InfoTipoScommessaGroupMap = {
  [key: string]: SportsInfoTipoScommessaGroupDto;
};

export type AvvenimentoList = Array<SportsAvvenimentoEsposto>;

export type ScommessaList = {
  key: string;
  filtro: ScommessaListFiltro;
  priorita: number;
  descrizione: string;
  idProgramma: number;
  idAvvenimento: number;
  idTipoScommessa: number;
};

export type ScommessaListFiltro = {
  quotaMin: number;
  quotaMax: number;
};

export type InfoEsitoMap = Record<string, SportsInfoEsito>;

export interface InfoTipoScommessa extends Omit<SportsInfoTipoScommessa, 'sizeInfoAggiuntive' | 'tipoVisualizzazione'> {
  sizeInfoAggiuntive: Array<SizeInfoAggiuntiva>;
  tipoVisualizzazione: VisualizationEnum;
  defaultNextInfoAggIndex?: number;
}

export interface ScommessaResponse
  extends Omit<SportsCacheScommesseCoreDto, 'esitoMap' | 'scommessaMap' | 'avvenimentoList' | 'infoAggiuntivaMap'> {
  slug?: string;
  esitoMap: EsitoMap;
  scommessaMap: ScommessaMap;
  infoEsitoMap: InfoEsitoMap;
  avvenimentoList: AvvenimentoList;
  infoTipoScommessaMap: InfoTipoScommessaMap;
  dettaglioAvvenimento?: SportsAvvenimentoEsposto;
  infoTipoScommessaGroupMap: InfoTipoScommessaGroupMap;
  isMarcatore?: boolean;
}

export const emptyScommessa: ScommessaResponse = {
  esitoMap: {},
  infoAggData: {},
  infoAggMeta: {},
  infoAggIndex: {},
  infoEsitoMap: {},
  scommessaMap: {},
  avvenimentoList: [],
  infoTipoScommessaMap: {},
  infoTipoScommessaGroupMap: {},
};

export type ScommessaResponsePayload = {
  data?: ScommessaResponse;
  status?: ApiStatusType;
};
