import { PHASE_DEVELOPMENT_SERVER, PHASE_PRODUCTION_BUILD } from 'next/constants';

import { CookiesOptions } from 'next-auth';
import { stringDictionary } from './types';

export const PAGE_LOADING = 'data-loading';
export const NAVIGATION_TO = 'data-navigate-to';
export const NAVIGATION_SRC = 'data-navigate-src';
export const NAVIGATION_ENDED = 'data-end-loading';
export const NAVIGATION_STARTED = 'data-st-loading';

export const IMAGE_EXTENTIONS = [
  '.apng',
  '.avif',
  '.bmp',
  '.cur',
  '.gif',
  '.ico',
  '.jfif',
  '.jif',
  '.jpeg',
  '.jpg',
  '.png',
  '.svg',
  '.tif',
  '.tiff',
  '.webp',
  '.xbm',
];

export const IS_NEXT_DEVELOPMENT_PHASE = process.env.NEXT_PHASE === PHASE_DEVELOPMENT_SERVER;
export const IS_NEXT_PRODUCTION_BUILD_PHASE = process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD;
export const SIGNALR_RECONNECTION_MAX_ATTEMPTS = 99;

export const breakpoint = {
  xs: 360,
  sm: 768,
  md: 1024,
  lg: 1350,
  xl: 1920,
};

export const cardWidth = {
  small: 303,
  big: 320,
};
/* eslint-disable no-unused-vars */
export enum SlotList {
  News = 'ListNovita',
  Popular = 'ListPopolari',
  Promotions = 'ListInPromozione',
  Jackpot = 'ListJackpot',
  ChoseFromSnai = '',
  InstantGame = '',
  All = '',
}

const GTMKEYS: Record<string, string> = {
  it: process.env.NEXT_PUBLIC_GTMCODE || '',
  de: 'GTM-MXGM56F7',
  at: 'GTM-MN5BPVNX',
};

export const GTAGKEYS: Record<string, string> = {
  de_reg: 'AW-372726570/WOhRCIqp2pQCEKq23bEB',
  at_reg: 'AW-372726141/gddBCIXB8-UCEP2y3bEB',
  de_ftd: 'AW-372726570/N_K6CM79h5UCEKq23bEB',
  at_ftd: 'AW-372726141/8nNZCNnW8-UCEP2y3bEB',
  de_dep: 'AW-372726570/TZWUCLfbgZUCEKq23bEB',
  at_dep: 'AW-372726141/hM3BCIjf8-UCEP2y3bEB',
};

export const GTMCODE = GTMKEYS[`${process.env.NEXT_PUBLIC_COUNTRY}`];

export const character = {
  poundSign: String.fromCharCode(35),
  minus: String.fromCharCode(45),
  colon: String.fromCharCode(58),
  whiteSpace: String.fromCharCode(160),
  apostrophe: String.fromCharCode(39),
};
// middleware list
//TODO cambiare nome dictionary
export const dictionaryOfSlot: stringDictionary = {
  'slot-novita': 'ListNovita',
  'slot-popolari': 'ListPopolari',
  'slot-in-promozione': 'ListInPromozione',
  jackpot: 'ListJackpot',
  'scelti-da-snai': '',
  'instant-game': '',
  'slot-tutte': '',
};
//TODO cambiare nome dictionary
export const dictionaryCasinoLive = {
  roulette: 'listRoulette',
  'altre-lingue': 'listAltreLingue',
  blackjack: 'listBlackjack',
  'in-italiano': 'listInItaliano',
  poker: 'listPoker',
};
export const dictionarySlotList = {
  mostPlayedList: 'listPiuGiocate',
  favoritesList: 'listPreferiti',
};
export const dictionaryPoker = {
  leavingList: 'listInPartenza',
};

export const pageSize = 48;

// in seconds
export const ISR_REVALIDATE = IS_NEXT_DEVELOPMENT_PHASE ? 15 : 40 * 60; // 40 minutes
export const SPORT_REVALIDATE = IS_NEXT_DEVELOPMENT_PHASE ? 15 : 5 * 60; // 5 min

// export const WINDOW_CLOSED = 'window_closed';

export const FIRTS_LOGIN_COOKIE = 'snai_first_login';

export const AKAMAI_SESSION_COOKIE = 'bm_sv';

export const urlMainTournaments = '/poker/tornei-principali/';
export const urlLeavingTournaments = '/poker/tornei-in-partenza/';

// FOR SLOT
export const MOST_PLAYED_LIST = 'listPiuGiocate';
export const FAVORITE_LIST = 'listPreferiti';

// FOR POKER
export const LEAVING_LIST = 'listInPartenza';

export const SEARCH_DELAY = 2000;

export const isSecureHost = `${process.env.NEXT_PUBLIC_BASE_URL}`.toLowerCase().startsWith(`https://`);

export const isLocalHost = ['localhost', '0.0.0.0', '127.0.0.1'].some((address) =>
  `${process.env.NEXT_PUBLIC_BASE_URL}`.toLowerCase().includes(`//${address}`)
);

export const isSnaiSite =
  `${process.env.NEXT_PUBLIC_COUNTRY}`.toLowerCase() === 'it' ||
  `${process.env.NEXT_PUBLIC_MODULE_SITE}`.toLowerCase() === 'snai';
export const isHappybetSite = `${process.env.NEXT_PUBLIC_MODULE_SITE}`.toLowerCase() === 'happybet';

export const isGermanSite = `${process.env.NEXT_PUBLIC_COUNTRY}`.toLowerCase() === 'de';
export const isAustrianSite = `${process.env.NEXT_PUBLIC_COUNTRY}`.toLowerCase() === 'at';

export const langs = isSnaiSite ? ['it'] : isGermanSite ? ['de', 'en', 'tr'] : ['de_AT', 'en_AT'];
export const defaultLang = isSnaiSite ? 'it' : isGermanSite ? 'de' : 'de_AT';

export const isRealTimeCashoutEnabled = ['true', '1', 'on', 'y', 'yes', 's'].includes(
  `${process.env.NEXT_PUBLIC_REALTIME_CASHOUT_ENABLED}`.toLowerCase()
);

export const TEXT_HIDDEN = '*******';

export const SISAL_SUPPLIER = 27;
export const LOTTOMATICA_SUPPLIER = 94;
export const GRATTA_E_VINCI_SUPPLIER = 180;
export const LOTTERIA_ITALIA_SUPPLIER = 181;

export const FROM_REGISTRATION = 'from_registration';

export const PROVENIENCE_QUERY_PARAM = 'provenienza';

export const SSR_ROOT_CACHE_PATH = [
  '.next',
  'cache',
  `${process.env.NEXT_PUBLIC_CACHE_VERSION ?? process.env.NEXT_PUBLIC_MODULE_SITE}`.split('.'),
].flat();

/* auth */
const [site, domain] = `${process.env.NEXT_PUBLIC_API_BASE_URL}`.split(/\.|\//gim).slice(3);
// token used to invoke MW end-points
export const TOKEN_BASE_NAME = `__${domain}-${site}:token`;
// contains json data expire-date (+ refreshToken for logged-in users)
export const TOKEN_BASE_REFRESH = `__${domain}-${site}:refresh`;
// flag indicating usr is logged -- TO BE DELETE: user is logged when refresh token exist
export const IS_LOGGED = `__${domain}-${site}-auth:logged`;
// falg indicating force logout
export const FORCE_LOGOUT = `__${domain}-${site}-auth:force_logout`;
// next-auth self-managed token
export const TOKEN_AUTH_NAME = `__${domain}-${site}-auth:token`;
// wrapper inactive logout ref
export const IDLE_KEY_NAME = `__${domain}-${site}-idle:mgmt`;
// refresh token ref
export const REFRESH_KEY_NAME = `__${domain}-${site}-refresh:gmgt`;
// check if client reach server
export const CONNECTED_KEY_NAME = `__${domain}-${site}-ping:gmgt`;

export const STORAGE_PREFIX_KEY = '~';

export const SESSION_UID = `${STORAGE_PREFIX_KEY}uID`;

export const SELECTED_CARD_COOKIE_NAME = `__${domain}-${site}:selected-card`;

const cookiesOptions = {
  path: '/',
  maxAge: 120 * 60,
  expires: null,
};
if (!isLocalHost) {
  Reflect.set(cookiesOptions, 'secure', true);
  // Reflect.set(cookiesOptions, 'domain', DOMAIN_BASE);
  Reflect.set(cookiesOptions, 'httpOnly', true);
  Reflect.set(cookiesOptions, 'sameSite', 'none');
}
export const cookies: Pick<CookiesOptions, 'sessionToken'> = {
  sessionToken: {
    name: TOKEN_AUTH_NAME,
    options: cookiesOptions,
  },
};

// folder access tokens
export const COMMON_MISC_FOLDER = 'common-misc';
export const TOKEN_LOCK_FOLDER = 'token-lock';

// EXCEPTIONS
export const JWT_DECODE_EXCEPTIONS = 'ERR_JWE_DECRYPTION_FAILED';
