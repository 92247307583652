import { isFrame, isSeamless } from 'utility/functions';
import { useAppDispatch, useTypedSelector } from 'lib/centralStore';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DeepPartial } from 'types/swagger';
import { Session } from 'next-auth';
import { setSession } from 'features/auth/authSlice';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { ApiStatus } from 'features/api/thunkUtils';
import { JWT_DECODE_EXCEPTIONS } from 'utility/constant';
import { signOut } from 'features/auth/authActions';
import { utcNow } from 'utility/date';

const isFrameDocument = isFrame();
const isSeamlessDocument = isSeamless();
const isStandAlone = isFrameDocument || isSeamlessDocument;

const enableTracing = process.env.NODE_ENV !== 'production';

/* eslint-disable  no-unused-vars */
export enum SessionState {
  loading = 'loading',
  authenticated = 'authenticated',
  unauthenticated = 'unauthenticated',
}
/* eslint-disable  no-unused-vars */

export type SessionStateType = `${SessionState}`;

export interface InternalSessionType {
  status: SessionStateType;
  session: DeepPartial<Session>;
  apiStatus: ApiStatus;
  isAuthenticated: boolean;
  updateSession: (_: DeepPartial<Session>) => void;
}

// iFrame required session to be filled server-side
export const useBaseSession = (): InternalSessionType => {
  const dispatch = useAppDispatch();
  const { isReady } = useRouter();
  const auth = useTypedSelector(({ auth }) => auth);

  const updateSession = useCallback(
    async (payload: DeepPartial<Session>) => {
      if (enableTracing) {
        console.log(`[AUTH] - Session update with`, payload);
      }
      const isAuthenticated = !!payload?.user ? true : false;
      dispatch(setSession({ ...payload, isAuthenticated }));
    },
    [dispatch]
  );

  // console.log(`limitemSession: status="${status}", isLogged="${isTruthy(cookie.getItem(IS_LOGGED))}"`)

  const { status, session } = useMemo(() => {
    let status = isReady ? SessionState.unauthenticated : SessionState.loading;

    if (auth) {
      return {
        status: auth.isLogged ? SessionState.authenticated : status,
        session: { ...auth } as unknown as Session,
      };
    }

    return {
      status,
      session: {} as unknown as Session,
    };
  }, [auth, isReady]);

  return {
    status: status ?? SessionState.unauthenticated,
    session,
    apiStatus: ApiStatus.idle,
    isAuthenticated: status === SessionState.authenticated,
    updateSession,
  };
};

export const useExtendedSession = (): InternalSessionType => {
  const { data, status: st, update } = useSession();
  const auth = useTypedSelector(({ auth }) => auth);
  const dispatch = useAppDispatch();

  const updateSession = useCallback(
    async (payload: DeepPartial<Session>) => {
      const isAuthenticated = payload?.user ? true : false;

      const nextSession: DeepPartial<Session> = { ...payload };
      Reflect.set(nextSession, 'isAuthenticated', isAuthenticated);
      dispatch(setSession({ ...payload, isAuthenticated }));
      try {
        await update(nextSession);
      } catch (e) {
        console.error(`[AUTH] - Session update failed with`, e);
      } finally {
      }
    },
    [dispatch, update]
  );

  // console.log(`standardSession: status="${status}", isLogged="${isTruthy(cookie.getItem(IS_LOGGED))}"`)

  const { status, session } = useMemo(() => {
    //apiStatus will be updated as soon as session response comes
    //apiStatus is invoked by RefreshTokenEngine hook
    let status = SessionState.loading;
    if (auth.apiStatus === ApiStatus.idle) {
      status = auth.isLogged ? SessionState.authenticated : SessionState.unauthenticated;
    }
    return {
      status,
      session: { ...data, ...auth } as unknown as Session,
    };
  }, [data, auth]);

  useEffect(() => {
    if (data?.error === JWT_DECODE_EXCEPTIONS) {
      dispatch(signOut({ dtNow: utcNow() }));
    }
  }, [dispatch, data?.error]);

  return {
    status: status ?? SessionState.unauthenticated,
    session,
    apiStatus: auth.apiStatus,
    isAuthenticated: status === SessionState.authenticated,
    updateSession,
  };
};

export const useInternalSession = isStandAlone ? useBaseSession : useExtendedSession;

export const useSessionPush = (session: Session): boolean => {
  const dispatch = useAppDispatch();
  const { isReady } = useRouter();
  const { updateSession } = useInternalSession();

  const isRaised = useRef(false);

  const [st, setSt] = useState<boolean>(false);

  useEffect(() => {
    const doDispatch = async () => {
      try {
        await updateSession(session);
      } finally {
        setTimeout(() => setSt(true), 299);
      }

      if (enableTracing) {
        console.log('useSessionPush.dispatch');
      }
    };

    if (isReady && !isRaised.current) {
      isRaised.current = true;
      doDispatch();
    }
  }, [session, isReady, dispatch, updateSession]);

  return st;
};
