/************************************************************************************************************
      DO NOT MODIFY IN THIS FILE
  ************************************************************************************************************/
import { SVGProps, useMemo, useRef, useState } from 'react';
import { isCrawler, isNotEmpty } from 'utility/functions';
import SVG from 'react-inlinesvg';
import type { IconProps } from 'components/Icons';
import { camelize, IconsEnum } from 'components/Icons';
import { VoucherDocument } from 'components/Icons/VoucherDocument';
import { VoucherLogo } from 'components/Icons/VoucherLogo';
import { VoucherUser } from 'components/Icons/VoucherUser';

const staticList: Array<string> = ['voucher-document', 'voucher-logo', 'voucher-user'];
const publicList: Record<string, string> = { stars: 'Stars.svg' };
const cmsList: Record<string, string> = {
  'snai-no-logo': '76629/1743677796-snai-no-logo.svg',
  background: '76629/1742978919-background.svg',
  match: '76629/1742911300-match.svg',
  'game-slash': '76629/1741954725-game-slash.svg',
  'trash-can': '76629/1741800019-trash-can.svg',
  'autoesclusion-24h': '76629/1741800019-autoesclusion-24h.svg',
  programmed: '76629/1741800019-programmed.svg',
  user: '76629/1741800019-user.svg',
  'goto-arrow': '76629/1741800019-goto-arrow.svg',
  'round-question': '76629/1741800019-round-question.svg',
  archive: '76629/1741800019-archive.svg',
  'download-apple': '76629/1741799885-download-apple.svg',
  'ferri-davanti': '76629/1741799838-ferri-davanti.svg',
  'ferri-dietro': '76629/1741799838-ferri-dietro.svg',
  'disciplina-128': '76629/1741799757-disciplina-128.svg',
  'disciplina-159-1': '76629/1741799757-disciplina-159-1.svg',
  'legenda-inattesa-dark': '76629/1741799278-legenda-inattesa-dark.svg',
  'legenda-chiusa-dark': '76629/1741799278-legenda-chiusa-dark.svg',
  'legenda-chiusa-light': '76629/1741799278-legenda-chiusa-light.svg',
  'legenda-inpagamento-dark': '76629/1741799278-legenda-inpagamento-dark.svg',
  'legenda-inattesa-light': '76629/1741799277-legenda-inattesa-light.svg',
  'legenda-aperta-dark': '76629/1741799278-legenda-aperta-dark.svg',
  'legenda-aperta-light': '76629/1741799278-legenda-aperta-light.svg',
  'legenda-inpagamento-light': '76629/1741799278-legenda-inpagamento-light.svg',
  'spid-logo': '76629/1741119681-spid-logo.svg',
  'smart-toy': '76629/1741110941-smart-toy.svg',
  'simplemessage-success': '76629/1741110941-simplemessage-success.svg',
  'link-external': '76629/1741110941-link-external.svg',
  'play-stroke': '76629/1741110941-play-stroke.svg',
  stats: '76629/1741110941-stats.svg',
  'exclamation-circle': '76629/1741110941-exclamation-circle.svg',
  trophy: '76629/1741110938-trophy.svg',
  ribbon: '76629/1741110941-ribbon.svg',
  picche: '76629/1741110938-picche.svg',
  quadri: '76629/1741110941-quadri.svg',
  pencil: '76629/1741110940-pencil.svg',
  'full-pin': '76629/1741110940-full-pin.svg',
  'heart-outline': '76629/1741110940-heart-outline.svg',
  'instant-panel': '76629/1741110937-instant-panel.webp',
  'preferred-off': '76629/1741110938-preferred-off.svg',
  'star-yellow': '76629/1741110936-star-yellow.png',
  image: '76629/1741110938-image.svg',
  player: '76629/1741110938-player.svg',
  'point-hamburger': '76629/1741110938-point-hamburger.svg',
  pinlocation: '76629/1741110938-pinlocation.svg',
  'simplemessage-information': '76629/1741110938-simplemessage-information.svg',
  questions: '76629/1741110938-questions.svg',
  'full-pinned': '76629/1741110937-full-pinned.svg',
  'new-light': '76629/1741110937-new-light.svg',
  'snai-runner': '76629/1741110937-snai-runner.svg',
  pin: '76629/1741110937-pin.svg',
  download: '76629/1741110937-download.svg',
  shield: '76629/1741110937-shield.svg',
  streaming: '76629/1741110937-streaming.svg',
  search: '76629/1741110936-search.svg',
  'new-dark': '76629/1741110936-new-dark.svg',
  'info-solid': '76629/1741110936-info-solid.svg',
  'eclamation-mark-outline': '76629/1741110936-eclamation-mark-outline.svg',
  live_hb: '76629/1741110936-live_hb.svg',
  'simplemessage-warning': '76629/1741110936-simplemessage-warning.svg',
  ticket: '76629/1741110936-ticket.svg',
  slots: '76629/1741110936-slots.svg',
  live: '76629/1741110935-live.svg',
  'search-off': '76629/1741110935-search-off.svg',
  'chewron-up': '76629/1741110935-chewron-up.svg',
  'reload-spin': '76629/1741110935-reload-spin.svg',
  message: '76629/1741110935-message.svg',
  cup: '76629/1741110935-cup.svg',
  'plus-circle': '76629/1741110935-plus-circle.svg',
  'laptop-mac': '76629/1741110935-laptop-mac.svg',
  menu: '76629/1741110935-menu.svg',
  'list-menu': '76629/1741110935-list-menu.svg',
  media: '76629/1741110932-media.svg',
  'simplemessage-critical': '76629/1741110934-simplemessage-critical.svg',
  dashboard: '76629/1741110934-dashboard.svg',
  'preferred-off-bck': '76629/1741110932-preferred-off-bck.svg',
  'minus-circle': '76629/1741110934-minus-circle.svg',
  'stadia-controller': '76629/1741110934-stadia-controller.svg',
  duplicate: '76629/1741110933-duplicate.svg',
  info: '76629/1741110932-info.svg',
  medal: '76629/1741110932-medal.svg',
  vector: '76629/1741110932-vector.svg',
  'pattinaggio-velocita': '76629/1741110931-pattinaggio-velocita.svg',
  tv: '76629/1741110931-tv.svg',
  share: '76629/1741110931-share.svg',
  trip: '76629/1741110931-trip.svg',
  form: '76629/1741110931-form.svg',
  'simplemessage-error': '76629/1741110931-simplemessage-error.svg',
  'star-outline': '76629/1741110931-star-outline.svg',
  'light-mode': '76629/1741110931-light-mode.svg',
  printer: '76629/1741110931-printer.svg',
  'match-live': '76629/1741110931-match-live.svg',
  gift: '76629/1741110930-gift.svg',
  star: '76629/1741110930-star.svg',
  success: '76629/1741110930-success.svg',
  radio: '76629/1741110930-radio.svg',
  pig: '76629/1741110930-pig.svg',
  'filter-vertical': '76629/1741110930-filter-vertical.svg',
  smartphone: '76629/1741110930-smartphone.svg',
  'arrow-up': '76629/1741110930-arrow-up.svg',
  'round-question-32': '76629/1741110930-round-question-32.svg',
  plus: '76629/1741110930-plus.svg',
  'chewron-down': '76629/1741110930-chewron-down.svg',
  'preferred-on': '76629/1741110929-preferred-on.svg',
  'arrow-decrease-light': '76629/1741110929-arrow-decrease-light.svg',
  alert: '76629/1741110929-alert.svg',
  cancel: '76629/1741110929-cancel.svg',
  'double-chevron-right': '76629/1741110925-double-chevron-right.svg',
  chart: '76629/1741110929-chart.svg',
  'calculator-md': '76629/1741110929-calculator-md.svg',
  'chewron-left': '76629/1741110929-chewron-left.svg',
  dices: '76629/1741110928-dices.svg',
  'cancel-full': '76629/1741110928-cancel-full.svg',
  'three-dots': '76629/1741110925-three-dots.svg',
  'eye-off': '76629/1741110928-eye-off.svg',
  'game-autoexclusion': '76629/1741110928-game-autoexclusion.svg',
  gear: '76629/1741110928-gear.svg',
  close: '76629/1741110928-close.svg',
  'dashboard-updated': '76629/1741110928-dashboard-updated.svg',
  filter: '76629/1741110928-filter.svg',
  'chewron-right': '76629/1741110928-chewron-right.svg',
  'controller-off': '76629/1741110925-controller-off.svg',
  minus: '76629/1741110928-minus.svg',
  'circle-warning': '76629/1741110928-circle-warning.svg',
  'happybet-logo': '76629/1741110925-happybet-logo.svg',
  deposit: '76629/1741110927-deposit.svg',
  exit: '76629/1741110925-exit.svg',
  'check-green': '76629/1741110925-check-green.svg',
  christmas: '76629/1741110923-christmas.png',
  calculator: '76629/1741110925-calculator.svg',
  calendar: '76629/1741110925-calendar.svg',
  'awesome-user': '76629/1741110925-awesome-user.svg',
  fiori: '76629/1741110924-fiori.svg',
  comment: '76629/1741110924-comment.svg',
  eye: '76629/1741110924-eye.svg',
  'arrow-increase-light': '76629/1741110924-arrow-increase-light.svg',
  checkmark: '76629/1741110924-checkmark.svg',
  'bonus-grey': '76629/1741110924-bonus-grey.svg',
  'info-yellow': '76629/1741110924-info-yellow.svg',
  chat: '76629/1741110924-chat.svg',
  cuori: '76629/1741110924-cuori.svg',
  arrow: '76629/1741110923-arrow.svg',
  backspace: '76629/1741110923-backspace.svg',
  galoppo: '76629/1741110923-galoppo.svg',
  'bonus-gold': '76629/1741110919-bonus-gold.svg',
  'dark-mode': '76629/1741110919-dark-mode.svg',
  'cash-multiple': '76629/1741110923-cash-multiple.svg',
  'arrow-increase': '76629/1741110922-arrow-increase.svg',
  'contract-pdf': '76629/1741110919-contract-pdf.svg',
  check: '76629/1741110919-check.svg',
  acme: '76629/1741110919-acme.svg',
  avatar: '76629/1741110919-avatar.svg',
  'arrow-back': '76629/1741110919-arrow-back.svg',
  'download-virtual': '76629/1741110918-download-virtual.svg',
  'close-circle': '76629/1741110918-close-circle.svg',
  account: '76629/1741110918-account.svg',
  bonus: '76629/1741110918-bonus.svg',
  'arrow-decrease': '76629/1741110918-arrow-decrease.svg',
  'adm-white-horizontal': '76629/1741110577-adm-white-horizontal.svg',
  'adm-white-18': '76629/1741110577-adm-white-18.svg',
  'adm-colorful-horizontal': '76629/1741110577-adm-colorful-horizontal.svg',
  'ferri-full': '76629/1741110518-ferri-full.svg',
  'ferri-none': '76629/1741110518-ferri-none.svg',
  'nazione-it': '76629/1741110002-nazione-it.svg',
  'snai-picto': '76629/1741110331-snai-picto.svg',
  'snai-logo': '76629/1741110331-snai-logo.svg',
  'snai-logo-short': '76629/1741110331-snai-logo-short.svg',
  'nazione-uk': '76629/1741110234-nazione-uk.svg',
  'legenda-tris-light': '76629/1741110105-legenda-tris-light.svg',
  'legenda-tris-dark': '76629/1741110105-legenda-tris-dark.svg',
  'legenda-tot-dark': '76629/1741110104-legenda-tot-dark.svg',
  'legenda-tqq-light': '76629/1741110104-legenda-tqq-light.svg',
  'legenda-annullata-dark': '76629/1741110104-legenda-annullata-dark.svg',
  'legenda-tqq-dark': '76629/1741110104-legenda-tqq-dark.svg',
  'legenda-annullata-light': '76629/1741110104-legenda-annullata-light.svg',
  'legenda-tot-light': '76629/1741110104-legenda-tot-light.svg',
  'nazione-sa': '76629/1741110005-nazione-sa.svg',
  'nazione-br': '76629/1741110005-nazione-br.svg',
  'nazione-ar': '76629/1741110005-nazione-ar.svg',
  'nazione-bh': '76629/1741110005-nazione-bh.svg',
  'nazione-fi': '76629/1741110005-nazione-fi.svg',
  'nazione-ca': '76629/1741110005-nazione-ca.svg',
  'nazione-co': '76629/1741110005-nazione-co.svg',
  'nazione-ph': '76629/1741110002-nazione-ph.svg',
  'nazione-bb': '76629/1741110005-nazione-bb.svg',
  'nazione-ke': '76629/1741110005-nazione-ke.svg',
  'nazione-us': '76629/1741110005-nazione-us.svg',
  'nazione-fr': '76629/1741110002-nazione-fr.svg',
  'nazione-dk': '76629/1741110005-nazione-dk.svg',
  'nazione-ie': '76629/1741110005-nazione-ie.svg',
  'nazione-kr': '76629/1741110005-nazione-kr.svg',
  'nazione-ag': '76629/1741110005-nazione-ag.svg',
  'nazione-au': '76629/1741110001-nazione-au.svg',
  'nazione-cl': '76629/1741110005-nazione-cl.svg',
  'nazione-gb': '76629/1741110004-nazione-gb.svg',
  'nazione-be': '76629/1741110004-nazione-be.svg',
  'nazione-ae': '76629/1741110002-nazione-ae.svg',
  'nazione-jp': '76629/1741110004-nazione-jp.svg',
  'nazione-hr': '76629/1741110002-nazione-hr.svg',
  'nazione-cy': '76629/1741110002-nazione-cy.svg',
  'nazione-de': '76629/1741110002-nazione-de.svg',
  'nazione-es': '76629/1741110002-nazione-es.svg',
  'nazione-at': '76629/1741110002-nazione-at.svg',
  'nazione-lb': '76629/1741110001-nazione-lb.svg',
  'nazione-ee': '76629/1741110001-nazione-ee.svg',
  'nazione-jm': '76629/1741110001-nazione-jm.svg',
  'nazione-gr': '76629/1741110001-nazione-gr.svg',
  'nazione-in': '76629/1741110001-nazione-in.svg',
  'nazione-az': '76629/1741110001-nazione-az.svg',
  'nazione-cn': '76629/1741110001-nazione-cn.svg',
  'meteo-nebbia': '76629/1741109975-meteo-nebbia.svg',
  'meteo-coperto': '76629/1741109975-meteo-coperto.svg',
  'meteo-vento': '76629/1741109975-meteo-vento.svg',
  'meteo-foschia': '76629/1741109975-meteo-foschia.svg',
  'meteo-pioggia': '76629/1741109975-meteo-pioggia.svg',
  'meteo-bello': '76629/1741109975-meteo-bello.svg',
  'meteo-variabile': '76629/1741109975-meteo-variabile.svg',
  cavallo: '76629/1741109877-cavallo.svg',
  trotto: '76629/1741109878-trotto.svg',
  'cavallo-paraocchi': '76629/1741109878-cavallo-paraocchi.svg',
  'disciplina-67': '76629/1741109498-disciplina-67.svg',
  'disciplina-03': '76629/1741109498-disciplina-03.svg',
  'disciplina-83': '76629/1741109497-disciplina-83.svg',
  'disciplina-107': '76629/1741109498-disciplina-107.svg',
  'disciplina-01': '76629/1741109498-disciplina-01.svg',
  'disciplina-119': '76629/1741109497-disciplina-119.svg',
  'disciplina-10': '76629/1741109497-disciplina-10.svg',
  'disciplina-134': '76629/1741109497-disciplina-134.svg',
  'disciplina-104': '76629/1741109497-disciplina-104.svg',
  'disciplina-126': '76629/1741109497-disciplina-126.svg',
  'disciplina-97': '76629/1741109497-disciplina-97.svg',
  'disciplina-70': '76629/1741109497-disciplina-70.svg',
  'disciplina-117': '76629/1741109497-disciplina-117.svg',
  'disciplina-158': '76629/1741109497-disciplina-158.svg',
  'disciplina-135': '76629/1741109497-disciplina-135.svg',
  'disciplina-47': '76629/1741109496-disciplina-47.svg',
  'disciplina-109': '76629/1741109496-disciplina-109.svg',
  'disciplina-34': '76629/1741109496-disciplina-34.svg',
  'disciplina-130': '76629/1741109496-disciplina-130.svg',
  'disciplina-75': '76629/1741109496-disciplina-75.svg',
  'disciplina-7': '76629/1741109496-disciplina-7.svg',
  'disciplina-112': '76629/1741109496-disciplina-112.svg',
  'disciplina-131': '76629/1741109496-disciplina-131.svg',
  'disciplina-86': '76629/1741109496-disciplina-86.svg',
  'disciplina-140': '76629/1741109496-disciplina-140.svg',
  'disciplina-65': '76629/1741109495-disciplina-65.svg',
  'disciplina-1001': '76629/1741109496-disciplina-1001.svg',
  'disciplina-121': '76629/1741109495-disciplina-121.svg',
  'disciplina-tiro-con-volo': '76629/1741109495-disciplina-tiro-con-volo.svg',
  'disciplina-esports-formula-e': '76629/1741109495-disciplina-esports-formula-e.svg',
  'disciplina-106': '76629/1741109495-disciplina-106.svg',
  'disciplina-124': '76629/1741109495-disciplina-124.svg',
  'disciplina-1027': '76629/1741109495-disciplina-1027.svg',
  'disciplina-30': '76629/1741109495-disciplina-30.svg',
  'disciplina-1026': '76629/1741109495-disciplina-1026.svg',
  'disciplina-92': '76629/1741109495-disciplina-92.svg',
  'disciplina-129': '76629/1741109492-disciplina-129.svg',
  'disciplina-118': '76629/1741109495-disciplina-118.svg',
  'disciplina-hockey-inliine': '76629/1741109492-disciplina-hockey-inliine.svg',
  'disciplina-61': '76629/1741109494-disciplina-61.svg',
  'disciplina-142': '76629/1741109492-disciplina-142.svg',
  'disciplina-59': '76629/1741109494-disciplina-59.svg',
  'disciplina-115': '76629/1741109494-disciplina-115.svg',
  'disciplina-46': '76629/1741109494-disciplina-46.svg',
  'disciplina-156': '76629/1741109492-disciplina-156.svg',
  'disciplina-148': '76629/1741109492-disciplina-148.svg',
  'disciplina-21': '76629/1741109494-disciplina-21.svg',
  'disciplina-90': '76629/1741109491-disciplina-90.svg',
  'disciplina-94': '76629/1741109491-disciplina-94.svg',
  'disciplina-152': '76629/1741109491-disciplina-152.svg',
  'disciplina-87': '76629/1741109491-disciplina-87.svg',
  'disciplina-88': '76629/1741109491-disciplina-88.svg',
  'disciplina-125': '76629/1741109491-disciplina-125.svg',
  'disciplina-123': '76629/1741109491-disciplina-123.svg',
  'disciplina-20': '76629/1741109491-disciplina-20.svg',
  'disciplina-sport-gaelici': '76629/1741109491-disciplina-sport-gaelici.svg',
  'disciplina-manifestazioni-spettacolo': '76629/1741109491-disciplina-manifestazioni-spettacolo.svg',
  'disciplina-esports-starcraft-2': '76629/1741109490-disciplina-esports-starcraft-2.svg',
  'disciplina-89': '76629/1741109490-disciplina-89.svg',
  'disciplina-73': '76629/1741109490-disciplina-73.svg',
  'disciplina-91': '76629/1741109490-disciplina-91.svg',
  'disciplina-77': '76629/1741109490-disciplina-77.svg',
  'disciplina-147': '76629/1741109490-disciplina-147.svg',
  'disciplina-64': '76629/1741109490-disciplina-64.svg',
  'disciplina-108': '76629/1741109490-disciplina-108.svg',
  'disciplina-136': '76629/1741109490-disciplina-136.svg',
  'disciplina-143': '76629/1741109490-disciplina-143.svg',
  'disciplina-157': '76629/1741109490-disciplina-157.svg',
  'disciplina-98': '76629/1741109490-disciplina-98.svg',
  'disciplina-80': '76629/1741109489-disciplina-80.svg',
  'disciplina-1013': '76629/1741109489-disciplina-1013.svg',
  'disciplina-99': '76629/1741109489-disciplina-99.svg',
  'disciplina-154': '76629/1741109489-disciplina-154.svg',
  'disciplina-esports-nba2k-league': '76629/1741109489-disciplina-esports-nba2k-league.svg',
  'disciplina-114': '76629/1741109489-disciplina-114.svg',
  'disciplina-1': '76629/1741109489-disciplina-1.svg',
  'disciplina-71': '76629/1741109489-disciplina-71.svg',
  'disciplina-150': '76629/1741109489-disciplina-150.svg',
  'disciplina-96': '76629/1741109489-disciplina-96.svg',
  'disciplina-41': '76629/1741109489-disciplina-41.svg',
  'disciplina-1028': '76629/1741109488-disciplina-1028.svg',
  'disciplina-100': '76629/1741109488-disciplina-100.svg',
  'disciplina-62': '76629/1741109488-disciplina-62.svg',
  'disciplina-132': '76629/1741109488-disciplina-132.svg',
  'disciplina-fallback': '76629/1741109488-disciplina-fallback.svg',
  'disciplina-40': '76629/1741109488-disciplina-40.svg',
  'disciplina-69': '76629/1741109488-disciplina-69.svg',
  'disciplina-110': '76629/1741109488-disciplina-110.svg',
  'disciplina-02': '76629/1741109486-disciplina-02.svg',
  'disciplina-1003': '76629/1741109486-disciplina-1003.svg',
  'disciplina-66': '76629/1741109486-disciplina-66.svg',
  'disciplina-105': '76629/1741109486-disciplina-105.svg',
  'disciplina-85': '76629/1741109486-disciplina-85.svg',
  'disciplina-pattinaggio-figurato': '76629/1741109486-disciplina-pattinaggio-figurato.svg',
  'disciplina-82': '76629/1741109486-disciplina-82.svg',
  'disciplina-22': '76629/1741109486-disciplina-22.svg',
  'disciplina-39': '76629/1741109486-disciplina-39.svg',
  'disciplina-esports-motociclismo': '76629/1741109485-disciplina-esports-motociclismo.svg',
  'disciplina-138': '76629/1741109485-disciplina-138.svg',
  'disciplina-55': '76629/1741109485-disciplina-55.svg',
  'disciplina-155': '76629/1741109485-disciplina-155.svg',
  'disciplina-1004': '76629/1741109485-disciplina-1004.svg',
  'disciplina-1007': '76629/1741109485-disciplina-1007.svg',
  'disciplina-120': '76629/1741109485-disciplina-120.svg',
  'disciplina-29': '76629/1741109485-disciplina-29.svg',
  'disciplina-28': '76629/1741109485-disciplina-28.svg',
  'disciplina-57': '76629/1741109485-disciplina-57.svg',
  'disciplina-53': '76629/1741109484-disciplina-53.svg',
  'disciplina-93': '76629/1741109484-disciplina-93.svg',
  'disciplina-48': '76629/1741109484-disciplina-48.svg',
  'disciplina-18': '76629/1741109484-disciplina-18.svg',
  'disciplina-60': '76629/1741109484-disciplina-60.svg',
  'disciplina-19': '76629/1741109484-disciplina-19.svg',
  'disciplina-103': '76629/1741109484-disciplina-103.svg',
  'disciplina-36': '76629/1741109484-disciplina-36.svg',
  'disciplina-16': '76629/1741109484-disciplina-16.svg',
  'disciplina-15': '76629/1741109483-disciplina-15.svg',
  'disciplina-27': '76629/1741109483-disciplina-27.svg',
  'disciplina-49': '76629/1741109483-disciplina-49.svg',
  'disciplina-95': '76629/1741109483-disciplina-95.svg',
  'disciplina-54': '76629/1741109483-disciplina-54.svg',
  'disciplina-81': '76629/1741109483-disciplina-81.svg',
  'disciplina-43': '76629/1741109483-disciplina-43.svg',
  'disciplina-23': '76629/1741109483-disciplina-23.svg',
  'disciplina-79': '76629/1741109483-disciplina-79.svg',
  'disciplina-003': '76629/1741109479-disciplina-003.svg',
  'disciplina-17': '76629/1741109482-disciplina-17.svg',
  'disciplina-3': '76629/1741109483-disciplina-3.svg',
  'disciplina-68': '76629/1741109482-disciplina-68.svg',
  'disciplina-42': '76629/1741109479-disciplina-42.svg',
  'disciplina-37': '76629/1741109482-disciplina-37.svg',
  'disciplina-127': '76629/1741109482-disciplina-127.svg',
  'disciplina-38': '76629/1741109482-disciplina-38.svg',
  'disciplina-58': '76629/1741109479-disciplina-58.svg',
  'disciplina-51': '76629/1741109482-disciplina-51.svg',
  'disciplina-102': '76629/1741109482-disciplina-102.svg',
  'disciplina-001': '76629/1741109482-disciplina-001.svg',
  'disciplina-44': '76629/1741109479-disciplina-44.svg',
  'disciplina-2': '76629/1741109479-disciplina-2.svg',
  'disciplina-116': '76629/1741109479-disciplina-116.svg',
  'disciplina-08': '76629/1741109479-disciplina-08.svg',
  'disciplina-6': '76629/1741109479-disciplina-6.svg',
  'disciplina-144': '76629/1741109479-disciplina-144.svg',
  'disciplina-12': '76629/1741109478-disciplina-12.svg',
  'disciplina-8': '76629/1741109478-disciplina-8.svg',
  'disciplina-52': '76629/1741109479-disciplina-52.svg',
  'disciplina-esports-world-of-warcraft': '76629/1741109478-disciplina-esports-world-of-warcraft.svg',
  'disciplina-78': '76629/1741109478-disciplina-78.svg',
  'disciplina-101': '76629/1741109478-disciplina-101.svg',
  'disciplina-9': '76629/1741109478-disciplina-9.svg',
  'disciplina-45': '76629/1741109478-disciplina-45.svg',
  'disciplina-24': '76629/1741109478-disciplina-24.svg',
  'disciplina-76': '76629/1741109478-disciplina-76.svg',
  'disciplina-14': '76629/1741109478-disciplina-14.svg',
  'disciplina-00': '76629/1741109478-disciplina-00.svg',
  'disciplina-13': '76629/1741109478-disciplina-13.svg',
  'disciplina-35': '76629/1741109477-disciplina-35.svg',
  'disciplina-33': '76629/1741109477-disciplina-33.svg',
  'disciplina-56': '76629/1741109477-disciplina-56.svg',
  'disciplina-31': '76629/1741109473-disciplina-31.svg',
  'disciplina-50': '76629/1741109473-disciplina-50.svg',
  'disciplina-32': '76629/1741109473-disciplina-32.svg',
  'disciplina-5': '76629/1741109473-disciplina-5.svg',
  'disciplina-11': '76629/1741109473-disciplina-11.svg',
  'message-center-ico-241': '76629/1741109407-message-center-ico-241.svg',
  'message-center-ico-404': '76629/1741109408-message-center-ico-404.svg',
  'message-center-ico-387': '76629/1741109410-message-center-ico-387.svg',
  'message-center-ico-371': '76629/1741109408-message-center-ico-371.svg',
  'message-center-ico-244': '76629/1741109409-message-center-ico-244.svg',
  'message-center-ico-291': '76629/1741109408-message-center-ico-291.svg',
  'message-center-ico-366': '76629/1741109408-message-center-ico-366.svg',
  'message-center-ico-225': '76629/1741109408-message-center-ico-225.svg',
  'message-center-ico-271': '76629/1741109407-message-center-ico-271.svg',
  'message-center-ico-375': '76629/1741109407-message-center-ico-375.svg',
  'message-center-ico-158': '76629/1741109407-message-center-ico-158.svg',
  'message-center-ico-53': '76629/1741109407-message-center-ico-53.svg',
  'message-center-ico-313': '76629/1741109407-message-center-ico-313.svg',
  'message-center-ico-319': '76629/1741109407-message-center-ico-319.svg',
  'message-center-ico-380': '76629/1741109407-message-center-ico-380.svg',
  'message-center-ico-201': '76629/1741109405-message-center-ico-201.svg',
  'message-center-ico-288': '76629/1741109407-message-center-ico-288.svg',
  'message-center-ico-314': '76629/1741109407-message-center-ico-314.svg',
  'message-center-ico-389': '76629/1741109407-message-center-ico-389.svg',
  'message-center-ico-397': '76629/1741109407-message-center-ico-397.svg',
  'message-center-ico-411': '76629/1741109405-message-center-ico-411.svg',
  'message-center-ico-395': '76629/1741109407-message-center-ico-395.svg',
  'message-center-ico-403': '76629/1741109407-message-center-ico-403.svg',
  'message-center-ico-381': '76629/1741109407-message-center-ico-381.svg',
  'message-center-ico-159': '76629/1741109404-message-center-ico-159.svg',
  'message-center-ico-416': '76629/1741109407-message-center-ico-416.svg',
  'message-center-ico-399': '76629/1741109407-message-center-ico-399.svg',
  'message-center-ico-386': '76629/1741109404-message-center-ico-386.svg',
  'message-center-ico-167': '76629/1741109407-message-center-ico-167.svg',
  'message-center-ico-406': '76629/1741109406-message-center-ico-406.svg',
  'message-center-ico-235': '76629/1741109404-message-center-ico-235.svg',
  'message-center-ico-421': '76629/1741109406-message-center-ico-421.svg',
  'message-center-ico-398': '76629/1741109405-message-center-ico-398.svg',
  'message-center-ico-376': '76629/1741109406-message-center-ico-376.svg',
  'message-center-ico-152': '76629/1741109406-message-center-ico-152.svg',
  'message-center-ico-217': '76629/1741109406-message-center-ico-217.svg',
  'message-center-ico-401': '76629/1741109404-message-center-ico-401.svg',
  'message-center-ico-329': '76629/1741109404-message-center-ico-329.svg',
  'message-center-ico-308': '76629/1741109404-message-center-ico-308.svg',
  'message-center-ico-413': '76629/1741109404-message-center-ico-413.svg',
  'message-center-ico-74': '76629/1741109404-message-center-ico-74.svg',
  'message-center-ico-129': '76629/1741109404-message-center-ico-129.svg',
  'message-center-ico-415': '76629/1741109404-message-center-ico-415.svg',
  'message-center-ico-372': '76629/1741109404-message-center-ico-372.svg',
  'message-center-ico-87': '76629/1741109404-message-center-ico-87.svg',
  'message-center-ico-140': '76629/1741109403-message-center-ico-140.svg',
  'message-center-ico-190': '76629/1741109403-message-center-ico-190.svg',
  'message-center-ico-43': '76629/1741109403-message-center-ico-43.svg',
  'message-center-ico-186': '76629/1741109403-message-center-ico-186.svg',
  'message-center-ico-363': '76629/1741109403-message-center-ico-363.svg',
  'message-center-ico-79': '76629/1741109403-message-center-ico-79.svg',
  'message-center-ico-400': '76629/1741109403-message-center-ico-400.svg',
  'message-center-ico-402': '76629/1741109403-message-center-ico-402.svg',
  'message-center-ico-317': '76629/1741109403-message-center-ico-317.svg',
  'message-center-ico-394': '76629/1741109403-message-center-ico-394.svg',
  'message-center-ico-419': '76629/1741109403-message-center-ico-419.svg',
  'message-center-ico-222': '76629/1741109403-message-center-ico-222.svg',
  'message-center-ico-180': '76629/1741109403-message-center-ico-180.svg',
  'message-center-ico-368': '76629/1741109403-message-center-ico-368.svg',
  'message-center-ico-417': '76629/1741109402-message-center-ico-417.svg',
  'message-center-ico-392': '76629/1741109402-message-center-ico-392.svg',
  'message-center-ico-408': '76629/1741109402-message-center-ico-408.svg',
  'message-center-ico-407': '76629/1741109402-message-center-ico-407.svg',
  'message-center-ico-412': '76629/1741109402-message-center-ico-412.svg',
  'message-center-ico-362': '76629/1741109402-message-center-ico-362.svg',
  'message-center-ico-390': '76629/1741109402-message-center-ico-390.svg',
  'message-center-ico-374': '76629/1741109402-message-center-ico-374.svg',
  'message-center-ico-298': '76629/1741109402-message-center-ico-298.svg',
  'message-center-ico-28': '76629/1741109402-message-center-ico-28.svg',
  'message-center-ico-303': '76629/1741109402-message-center-ico-303.svg',
  'message-center-ico-230': '76629/1741109402-message-center-ico-230.svg',
  'message-center-ico-418': '76629/1741109399-message-center-ico-418.svg',
  'message-center-ico-294': '76629/1741109401-message-center-ico-294.svg',
  'message-center-ico-369': '76629/1741109399-message-center-ico-369.svg',
  'message-center-ico-365': '76629/1741109401-message-center-ico-365.svg',
  'message-center-ico-393': '76629/1741109401-message-center-ico-393.svg',
  'message-center-ico-61': '76629/1741109401-message-center-ico-61.svg',
  'message-center-ico-420': '76629/1741109401-message-center-ico-420.svg',
  'message-center-ico-396': '76629/1741109401-message-center-ico-396.svg',
  'message-center-ico-373': '76629/1741109401-message-center-ico-373.svg',
  'message-center-ico-264': '76629/1741109401-message-center-ico-264.svg',
  'message-center-ico-385': '76629/1741109401-message-center-ico-385.svg',
  'message-center-ico-391': '76629/1741109401-message-center-ico-391.svg',
  'message-center-ico-367': '76629/1741109401-message-center-ico-367.svg',
  'message-center-ico-377': '76629/1741109401-message-center-ico-377.svg',
  'message-center-ico-151': '76629/1741109399-message-center-ico-151.svg',
  'message-center-ico-409': '76629/1741109398-message-center-ico-409.svg',
  'message-center-ico-379': '76629/1741109398-message-center-ico-379.svg',
  'message-center-ico-245': '76629/1741109398-message-center-ico-245.svg',
  'message-center-ico-405': '76629/1741109398-message-center-ico-405.svg',
  'message-center-ico-388': '76629/1741109398-message-center-ico-388.svg',
  'message-center-ico-414': '76629/1741109398-message-center-ico-414.svg',
  'message-center-ico-248': '76629/1741109398-message-center-ico-248.svg',
  'message-center-ico-259': '76629/1741109398-message-center-ico-259.svg',
  'message-center-ico-71': '76629/1741109396-message-center-ico-71.svg',
  'message-center-ico-40': '76629/1741109398-message-center-ico-40.svg',
  'message-center-ico-205': '76629/1741109398-message-center-ico-205.svg',
  'message-center-ico-301': '76629/1741109397-message-center-ico-301.svg',
  'message-center-ico-141': '76629/1741109397-message-center-ico-141.svg',
  'message-center-ico-120': '76629/1741109397-message-center-ico-120.svg',
  'message-center-ico-25': '76629/1741109397-message-center-ico-25.svg',
  'message-center-ico-21': '76629/1741109397-message-center-ico-21.svg',
  'message-center-ico-364': '76629/1741109397-message-center-ico-364.svg',
  'message-center-ico-210': '76629/1741109397-message-center-ico-210.svg',
  'message-center-ico-215': '76629/1741109397-message-center-ico-215.svg',
  'message-center-ico-196': '76629/1741109396-message-center-ico-196.svg',
  'message-center-ico-52': '76629/1741109396-message-center-ico-52.svg',
  'message-center-ico-98': '76629/1741109396-message-center-ico-98.svg',
  'message-center-ico-384': '76629/1741109396-message-center-ico-384.svg',
  'message-center-ico-382': '76629/1741109396-message-center-ico-382.svg',
  'message-center-ico-76': '76629/1741109396-message-center-ico-76.svg',
  'message-center-ico-57': '76629/1741109396-message-center-ico-57.svg',
  'message-center-ico-163': '76629/1741109396-message-center-ico-163.svg',
  'message-center-ico-188': '76629/1741109396-message-center-ico-188.svg',
  'message-center-ico-283': '76629/1741109393-message-center-ico-283.svg',
  'message-center-ico-102': '76629/1741109393-message-center-ico-102.svg',
  'message-center-ico-173': '76629/1741109396-message-center-ico-173.svg',
  'message-center-ico-82': '76629/1741109396-message-center-ico-82.svg',
  'message-center-ico-101': '76629/1741109396-message-center-ico-101.svg',
  'message-center-ico-254': '76629/1741109396-message-center-ico-254.svg',
  'message-center-ico-146': '76629/1741109395-message-center-ico-146.svg',
  'message-center-ico-49': '76629/1741109395-message-center-ico-49.svg',
  'message-center-ico-134': '76629/1741109393-message-center-ico-134.svg',
  'message-center-ico-85': '76629/1741109393-message-center-ico-85.svg',
  'message-center-ico-109': '76629/1741109393-message-center-ico-109.svg',
  'message-center-ico-370': '76629/1741109393-message-center-ico-370.svg',
  'message-center-ico-42': '76629/1741109393-message-center-ico-42.svg',
  'message-center-ico-8': '76629/1741109389-message-center-ico-8.svg',
  'message-center-ico-309': '76629/1741109392-message-center-ico-309.svg',
  'message-center-ico-410': '76629/1741109392-message-center-ico-410.svg',
  'message-center-ico-199': '76629/1741109392-message-center-ico-199.svg',
  'message-center-ico-238': '76629/1741109389-message-center-ico-238.svg',
  'message-center-ico-12': '76629/1741109389-message-center-ico-12.svg',
  'message-center-ico-383': '76629/1741109392-message-center-ico-383.svg',
  'message-center-ico-276': '76629/1741109392-message-center-ico-276.svg',
  'message-center-ico-73': '76629/1741109392-message-center-ico-73.svg',
  'message-center-ico-147': '76629/1741109389-message-center-ico-147.svg',
  'message-center-ico-55': '76629/1741109392-message-center-ico-55.svg',
  'message-center-ico-69': '76629/1741109392-message-center-ico-69.svg',
  'message-center-ico-91': '76629/1741109392-message-center-ico-91.svg',
  'message-center-ico-47': '76629/1741109392-message-center-ico-47.svg',
  'message-center-ico-378': '76629/1741109392-message-center-ico-378.svg',
  'message-center-ico-59': '76629/1741109391-message-center-ico-59.svg',
  'message-center-ico-11': '76629/1741109389-message-center-ico-11.svg',
  'message-center-ico-252': '76629/1741109389-message-center-ico-252.svg',
  'message-center-ico-17': '76629/1741109389-message-center-ico-17.svg',
  'message-center-ico-36': '76629/1741109389-message-center-ico-36.svg',
  'message-center-ico-105': '76629/1741109389-message-center-ico-105.svg',
  'message-center-ico-63': '76629/1741109389-message-center-ico-63.svg',
  'message-center-ico-41': '76629/1741109388-message-center-ico-41.svg',
  'message-center-ico-208': '76629/1741109388-message-center-ico-208.svg',
};

const indexing = isCrawler();

const Static = (props: IconProps): JSX.Element => {
  const { iconId, ...oth } = props;
  /* eslint-disable @next/next/no-img-element, jsx-a11y/alt-text */
  switch (iconId) {
    case IconsEnum.VOUCHER_DOCUMENT: {
      return <VoucherDocument {...(oth as SVGProps<SVGSVGElement>)} suppressHydrationWarning={true} />;
    }
    case IconsEnum.VOUCHER_LOGO: {
      return <VoucherLogo {...(oth as SVGProps<SVGSVGElement>)} suppressHydrationWarning={true} />;
    }
    case IconsEnum.VOUCHER_USER: {
      return <VoucherUser {...(oth as SVGProps<SVGSVGElement>)} suppressHydrationWarning={true} />;
    }
    default: {
      return (
        <svg
          role="img"
          aria-label="fallback"
          className="snai-image snai-image-fallback"
          {...(oth as SVGProps<SVGSVGElement>)}
          suppressHydrationWarning={true}
        />
      );
    }
  }
  /* eslint-enable jsx-a11y/alt-text, @next/next/no-img-element */
};

const Dynamic = ({ iconId, className, ...oth }: IconProps): JSX.Element => {
  /* const Dynamic = memo(function Load ({ iconId, className, ...oth }: IconProps): JSX.Element { */

  const refCall = useRef(false);
  const [fallback, setFallback] = useState('');
  const componentClassName = ['snai-image', `snai-image-${iconId}`, className ?? ''].join(' ');

  const objLoader = (
    <svg
      role="img"
      aria-label="fallback"
      className={`${componentClassName} snai-image-fallback`}
      {...(oth as SVGProps<SVGSVGElement>)}
      suppressHydrationWarning={true}
    />
  );

  const { imageSrc, isSvg } = useMemo(() => {
    let imageSrc = '';
    const key = `${iconId}`.toLowerCase();
    if (Object.hasOwn(cmsList, key)) {
      imageSrc = `https://www.datocms-assets.com/${cmsList[key]}`;
    } else if (Object.hasOwn(publicList, key)) {
      imageSrc = `${process.env.NEXT_PUBLIC_BASE_URL}/Icons/${publicList[key]}`;
    } else if (!refCall.current && !fallback && isNotEmpty(key)) {
      refCall.current = true;
      const params = new URLSearchParams();
      params.set('rsx', key);
      params.set('ts', '1743779404644');
      fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/img-loader?${params.toString()}`).then(async (res) => {
        if (res.ok) {
          const data = await res.json();
          setFallback(data.url);
        }
      });
    }
    const isSvg = indexing ? false : `${imageSrc}`.toLowerCase().endsWith('.svg');
    return { imageSrc, isSvg };
  }, [iconId]);

  if (!iconId && !imageSrc && !fallback) {
    return objLoader;
  }

  if (isSvg) {
    return (
      <SVG
        {...(oth as any)}
        src={`${imageSrc || fallback}?ts=1743779404644`}
        role="img"
        loader={objLoader}
        loading="lazy"
        decoding="async"
        className={componentClassName}
        cacheRequests={true}
        suppressHydrationWarning={true}
      />
    );
  }

  return (
    // eslint-disable-next-line @next/next/no-img-element, jsx-a11y/alt-text
    <img
      {...(oth as any)}
      src={`${imageSrc || fallback}?ts=1743779404644`}
      loading="lazy"
      decoding="async"
      className={componentClassName}
      suppressHydrationWarning={true}
    />
  );
};

export const Icon = ({ iconId, ...props }: IconProps): JSX.Element => {
  const isStatic = useMemo(
    () => staticList.some((x) => camelize(`${x}`).toLowerCase() === camelize(`${iconId}`).toLowerCase()),
    [iconId]
  );
  if (isStatic) {
    return <Static key={iconId} {...props} iconId={iconId} />;
  }
  return <Dynamic key={iconId} {...props} iconId={iconId} />;
};
